import { useCallback, useState, useEffect } from 'react';
import * as optimismSDK from '@eth-optimism/sdk';
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';
import { toast } from '@node-real/uikit';
import { BigNumber, ethers } from 'ethers';

import { contracts } from '../base/sdkContract';
import * as env from '../env';
import { usePending } from './usePending';
import { useTxFailed } from './useTxFailed';
import { batchUpdate } from '../utils';

const predeploys = require('@eth-optimism/contracts');

const l1ChainId = Number(env.L1_CHAIN_ID);
const l2ChainId = Number(env.L2_CHAIN_ID);
const selfL1StandardBridge = env.L1StandardBridge;
const l2RpcProvider = new ethers.providers.JsonRpcProvider(env.L2_RPC_URL, 'any');

export const useL1Withdraw = () => {
  const [crossChainMessenger, setCrossChainMessenger] =
    useState<optimismSDK.CrossChainMessenger | null>(null);
  const [waitingConfirmation, setWaitingConfirmation] = useState(false);
  const [txProveFailedMsg, setTxProveFailedMsg] = useState('');
  const [txFinalizeFailedMsg, setTxFinalizeFailedMsg] = useState('');

  const { address, connector } = useAccount();
  const { chain } = useNetwork();

  const { isLoading, switchNetworkAsync } = useSwitchNetwork({
    onError: (err: any) => {
      toast.error({
        description: err.message,
      });
    },
  });
  const { showPending, handlePendingShow, handlePendingToggle } = usePending();
  const { showError, handleErrorShow, handleErrorToggle } = useTxFailed();

  function useMySigner({ chainId }: { chainId: number }) {
    const [signer, setSigner] = useState(null);
    const { connector } = useAccount();

    useEffect(() => {
      const fetchSigner = async () => {
        const signer = (await connector?.getSigner?.({ chainId })) || null;
        setSigner(signer);
      };

      fetchSigner();
    }, [chainId, connector, address]);

    return { data: signer };
  }

  const { data: signer1 } = useMySigner({
    chainId: l1ChainId,
  });

  useEffect(() => {
    if (address) {
      const l2Signer = l2RpcProvider.getSigner(address);
      if (l2Signer && signer1) {
        try {
          const messenger = new optimismSDK.CrossChainMessenger({
            l1ChainId: l1ChainId,
            l2ChainId: l2ChainId,
            l1SignerOrProvider: signer1,
            l2SignerOrProvider: l2Signer,
            bedrock: true,
            contracts: contracts,
          });
          const bridgess = optimismSDK.getBridgeAdapters(l2ChainId, messenger, {
            overrides: {
              Standard: {
                Adapter: optimismSDK.StandardBridgeAdapter,
                l1Bridge: selfL1StandardBridge,
                l2Bridge: predeploys.predeploys.L2StandardBridge,
              },
              ETH: {
                Adapter: optimismSDK.ETHBridgeAdapter,
                l1Bridge: selfL1StandardBridge,
                l2Bridge: predeploys.predeploys.L2StandardBridge,
              },
            },
          });

          messenger.bridges = bridgess;
          // overwrite getMessageBedrockOutput to get the latest proposal.
          // This fixes initial withdraw processed more than 6 months ago.
          // @ts-ignore
          messenger.__proto__.getMessageBedrockOutput = async (
            message: optimismSDK.MessageLike,
          ) => {
            const resolved = await messenger.toCrossChainMessage(message);

            // Outputs are only a thing for L2 to L1 messages.
            if (resolved.direction === optimismSDK.MessageDirection.L1_TO_L2) {
              throw new Error(`cannot get a state root for an L1 to L2 message`);
            }

            // Try to find the output index that corresponds to the block number attached to the message.
            // We'll explicitly handle "cannot get output" errors as a null return value, but anything else
            // needs to get thrown. Might need to revisit this in the future to be a little more robust
            // when connected to RPCs that don't return nice error messages.
            let l2OutputIndex: BigNumber;
            try {
              /** OLD code */
              // l2OutputIndex = await messenger.contracts.l1.L2OutputOracle.getL2OutputIndexAfter(
              //   resolved.blockNumber,
              // );

              /** NEW code */
              l2OutputIndex = await messenger.contracts.l1.L2OutputOracle.latestOutputIndex();
            } catch (err: any) {
              if (err.message.includes('L2OutputOracle: cannot get output')) {
                return null;
              } else {
                throw err;
              }
            }

            // Now pull the proposal out given the output index. Should always work as long as the above
            // codepath completed successfully.
            // eslint-disable-next-line no-console
            console.log('new getMessageBedrockOutput');
            const proposal = await messenger.contracts.l1.L2OutputOracle.getL2Output(l2OutputIndex);

            // Format everything and return it nicely.
            return {
              outputRoot: proposal.outputRoot,
              l1Timestamp: proposal.timestamp.toNumber(),
              l2BlockNumber: proposal.l2BlockNumber.toNumber(),
              l2OutputIndex: l2OutputIndex.toNumber(),
            };
          };
          setCrossChainMessenger(messenger);
        } catch (e) {
          //eslint-disable-next-line no-console
          console.log(e);
        }
      }
    }
  }, [address, chain, connector, signer1]);

  const proveWithdrawCore = useCallback(
    async (tx: string) => {
      if (crossChainMessenger && tx) {
        try {
          // show pending modal
          handlePendingShow(true);
          setWaitingConfirmation(true);

          // start prove withdraw
          const proveResponse = await crossChainMessenger.proveMessage(tx);
          // eslint-disable-next-line no-console
          console.log(proveResponse);

          setWaitingConfirmation(false);
          handlePendingShow(false);

          return proveResponse.hash;
        } catch (e: any) {
          setWaitingConfirmation(false);
          handlePendingShow(false);

          if (e && e?.code) {
            if (e?.code === 'TRANSACTION_REPLACED' && e?.replacement?.hash) {
              // eslint-disable-next-line no-console
              console.log(e.replacement);
              return e.replacement.hash;
            } else {
              batchUpdate(() => {
                setTxProveFailedMsg(e?.code || '');
                handleErrorShow(true);
              });
            }
            // eslint-disable-next-line no-console
            console.log(e?.code);
          } else {
            batchUpdate(() => {
              setTxProveFailedMsg(e.message || 'Prove Withdraw Failed');
              handleErrorShow(true);
            });
          }
          // eslint-disable-next-line no-console
          console.log(e);
          return null;
        }
      }
    },
    [crossChainMessenger, handleErrorShow, handlePendingShow],
  );

  const finalizeWithdrawCore = useCallback(
    async (tx: string) => {
      if (crossChainMessenger && tx) {
        try {
          // show pending modal
          handlePendingShow(true);
          setWaitingConfirmation(true);

          // start finalize withdraw
          const finalizedResponse = await crossChainMessenger.finalizeMessage(tx);

          setWaitingConfirmation(false);
          handlePendingShow(false);

          // eslint-disable-next-line no-console
          console.log(finalizedResponse);

          return finalizedResponse?.hash;
        } catch (e: any) {
          setWaitingConfirmation(false);
          handlePendingShow(false);

          if (e && e?.code) {
            if (e?.code === 'TRANSACTION_REPLACED' && e?.replacement?.hash) {
              // eslint-disable-next-line no-console
              console.log(e.replacement);
              return e.replacement.hash;
            } else {
              batchUpdate(() => {
                setTxFinalizeFailedMsg(e?.code);
                handleErrorShow(true);
              });
            }
            // eslint-disable-next-line no-console
            console.log(e?.code);
          } else {
            batchUpdate(() => {
              setTxFinalizeFailedMsg(e || 'Finalize Withdraw Failed');
              handleErrorShow(true);
            });
          }
          // eslint-disable-next-line no-console
          console.log(e);
          return null;
        }
      }
    },
    [crossChainMessenger, handleErrorShow, handlePendingShow],
  );

  const proveWithdraw = useCallback(
    async (tx: string) => {
      if (chain && chain.id === Number(env.L1_CHAIN_ID)) {
        return await proveWithdrawCore(tx);
      } else if (switchNetworkAsync && !isLoading) {
        return await switchNetworkAsync?.(Number(env.L1_CHAIN_ID))
          .then(async () => {
            return await proveWithdrawCore(tx);
          })
          .catch((e: any) => {
            setWaitingConfirmation(false);
            handlePendingShow(false);

            if (e && e?.code) {
              if (e?.code === 'TRANSACTION_REPLACED' && e?.replacement?.hash) {
                // eslint-disable-next-line no-console
                console.log(e.replacement);
                return e.replacement.hash;
              } else {
                batchUpdate(() => {
                  setTxProveFailedMsg(e?.code || '');
                  handleErrorShow(true);
                });
              }
              // eslint-disable-next-line no-console
              console.log(e?.code);
            }
            // eslint-disable-next-line no-console
            console.log(e);
            return null;
          });
      }
    },
    [chain, isLoading, switchNetworkAsync, proveWithdrawCore, handleErrorShow, handlePendingShow],
  );

  const finalWithdraw = useCallback(
    async (tx: string) => {
      if (chain && chain.id === Number(env.L1_CHAIN_ID)) {
        return await finalizeWithdrawCore(tx);
      } else if (switchNetworkAsync && !isLoading) {
        return await switchNetworkAsync?.(Number(env.L1_CHAIN_ID))
          .then(async () => {
            return await finalizeWithdrawCore(tx);
          })
          .catch((e: any) => {
            setWaitingConfirmation(false);
            handlePendingShow(false);

            if (e && e?.code) {
              if (e?.code === 'TRANSACTION_REPLACED' && e?.replacement?.hash) {
                // eslint-disable-next-line no-console
                console.log(e.replacement);
                return e.replacement.hash;
              } else {
                batchUpdate(() => {
                  setTxFinalizeFailedMsg(e?.code || '');
                  handleErrorShow(true);
                });
              }
              // eslint-disable-next-line no-console
              console.log(e?.code);
            }
            // eslint-disable-next-line no-console
            console.log(e);
            return null;
          });
      }
    },
    [
      chain,
      isLoading,
      finalizeWithdrawCore,
      switchNetworkAsync,
      handleErrorShow,
      handlePendingShow,
    ],
  );

  const checkWithdrawStatus = useCallback(
    async (tx: string) => {
      if (chain && chain.id === Number(env.L1_CHAIN_ID)) {
        if (crossChainMessenger && tx) {
          try {
            await crossChainMessenger.waitForMessageStatus(
              tx,
              optimismSDK.MessageStatus.READY_TO_PROVE,
            );
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
          }
        }
      }
    },
    [crossChainMessenger, chain],
  );

  return {
    proveWithdraw,
    finalWithdraw,
    checkWithdrawStatus,
    waitingConfirmation,
    showPending,
    handlePendingShow,
    handlePendingToggle,
    txProveFailedMsg,
    setTxProveFailedMsg,
    txFinalizeFailedMsg,
    setTxFinalizeFailedMsg,
    showError,
    handleErrorShow,
    handleErrorToggle,
    crossChainMessenger,
  };
};
