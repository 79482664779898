import React, { ReactNode, useReducer } from 'react';

import { AutoWithdrawReducer, AutoWithdrawContext, initialState } from '.';

interface AutoWithdrawProviderProps {
  children: ReactNode;
}

export const AutoWithdrawProvider: React.ComponentType<AutoWithdrawProviderProps> = (
  props: AutoWithdrawProviderProps,
) => {
  const { children } = props;
  const [autoWithdrawState, autoWithdrawDispatch] = useReducer(AutoWithdrawReducer, initialState);

  return (
    <AutoWithdrawContext.Provider value={{ autoWithdrawState, autoWithdrawDispatch }}>
      {children}
    </AutoWithdrawContext.Provider>
  );
};
AutoWithdrawProvider.displayName = 'AutoWithdrawProvider';
