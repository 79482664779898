import { Modal, Flex, ModalCloseButton, Box, Button } from '@node-real/uikit';
import styled from '@emotion/styled';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  SwitchNetworkButton,
  TokenObjectType,
  mobileMedia,
  useWithdrawHistory,
} from '@op-bridge/bridge-core';
import { ColoredWarningIcon } from '@node-real/icons';
import { useCallback, useEffect, useState } from 'react';
import { useAccount, useNetwork } from 'wagmi';

import { GA_MAP, reportEvent } from '../../utils/ga';
import * as env from '../../env';
import { DepositButton } from '../Deposit';
import {
  getDataFromCache,
  batchUpdate,
  removeTrailingZero,
  deleteDataFromCache,
} from '../../utils';
import { Loading, SpinImage } from '../Loading';
import { useAutoWithdraw, useGetAutoWithdrawAllowance, useL1Withdraw } from '../../hooks';
import { TxLoading, WithdrawProgress } from './WithdrawProgress';
import { TxError } from './TxError';
import { ReturnAfter } from '../ongoing/ReturnAfter';
import { AutoWithdrawSwitch } from '../autoWithdraw/AutoWithdrawSwitch';
import { AutoGasFee } from './gasFee/AutoGasFee';
import { EstimatedArrival } from '../ongoing/EstimatedArrival';
import { FinalizeButton } from '../button/FinalizeButton';
import { ProveButton } from '../button/ProveButton';
import { WithdrawalCheckBox } from './checkbox';
import { ApproveL2TokenButton } from '../button/ApproveL2TokenButton';

const getHistoryCache = async () => {
  if (typeof window !== 'undefined') {
    const cache = await getDataFromCache(window.location.hostname);
    if (cache) {
      // console.log(cache);
      return cache;
    }
  }
};

interface ConfirmPopupProps {
  isOpen: boolean;
  handleOpen: (show: boolean) => void;
  tokenType: string;
  asset: TokenObjectType[];
  withdrawFun: any;
  isSufficient: boolean;
  withdrawVal: string;
  clearInput: () => void;
  handleErrorShowErc20: (show: boolean) => void;
  setTxFailedMsgErc20: (msg: string) => void;
  withdrawERC20: (value: string, asset: TokenObjectType) => Promise<string>;
  tokenPrice: number;
  isLoadingPrice: boolean;
  isLoadingGasPrice: boolean;
  isCalcPrice: boolean;
  l1DataGas: number;
  l2GasPrice: number;
  l1GasPrice: number;
  estimateGas: number;
  bnbPrice: number;
  l2BalanceVal: number;
}

const TxStatus = [
  '',
  env.NET_ENV === 'Testnet'
    ? `Return in 15 mins to Prove Withdrawal`
    : `Return in 2 hrs to Prove Withdrawal`,
  'Prove Withdrawal',
  env.NET_ENV === 'Testnet'
    ? 'Return in 30 mins to Finalize Withdrawal'
    : 'Return in 7 days to Finalize Withdrawal',
  'Finalize Withdrawal',
  'View in History',
];

export const ConfirmWithdraw = (props: ConfirmPopupProps) => {
  const {
    isOpen,
    handleOpen,
    tokenType,
    asset,
    isSufficient,
    withdrawFun: withdraw,
    withdrawVal,
    handleErrorShowErc20,
    setTxFailedMsgErc20,
    withdrawERC20,
    tokenPrice,
    isLoadingGasPrice,
    isLoadingPrice, // get estimate gas loading
    isCalcPrice,
    l1DataGas,
    l2GasPrice,
    l1GasPrice,
    estimateGas,
    bnbPrice,
    clearInput,
    l2BalanceVal,
  } = props;
  const { isConnected, address: wagmiAddress } = useAccount();
  const { chain: currentChain } = useNetwork();
  const { autoWithdraw, txFailedMsg } = useAutoWithdraw();
  const router = useRouter() as any;

  const [initiateHash, setInitHash] = useState(''); // Bep20 token or BNB initiate hash
  const [proveHash, setProveHash] = useState('');
  const [finalizeHash, setFinalizeHash] = useState('');
  const {
    proveWithdraw,
    finalWithdraw,
    txProveFailedMsg,
    setTxProveFailedMsg,
    txFinalizeFailedMsg,
    setTxFinalizeFailedMsg,
    showError,
    handleErrorShow,
  } = useL1Withdraw();
  const [isConfirming, setIsConfirming] = useState(false); // confirm withdraw in wallet

  const [isAutoWithdraw, setIsAutoWithdraw] = useState(false); // auto withdraw switch
  const [tickBoxOne, setTickBoxOne] = useState(false);
  const [address, setAddress] = useState<string | null>(null);
  const [historyCache, setHistoryCache] = useState<{ [key: string]: string } | null>(null);
  const [currentTx, setCurrentTx] = useState<any>(null);
  const [tmpCountTime, setTmpCountTime] = useState(0);
  const [tokenAllowance, setTokenAllowance] = useState<number>(0);
  const { refetch } = useWithdrawHistory(address, 1, 100);
  const { getL2Allowance, isLoadingL2Allowance } = useGetAutoWithdrawAllowance();
  /**
   * step 0 - Not yet initiate withdrawal
   * step 1 - Initiate withdrawal completed, waiting to prove - Return in 2 hrs to Prove Withdrawal
   * step 2 - Ready to prove
   * step 3 - Prove withdrawal completed, waiting to finalize - Finalizing Withdrawal
   * step 4 - Ready to finalize
   * step 5 - Finalize withdrawal completed
   */
  const step =
    initiateHash && !currentTx ? 1 : (currentTx && Number(currentTx.receiptsStatus)) || 0;

  useEffect(() => {
    if (wagmiAddress) setAddress(wagmiAddress);
  }, [wagmiAddress]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      getHistoryCache().then((res) => {
        if (res) {
          setHistoryCache(res);
        } else {
          setHistoryCache(null);
        }
      });
    }
  }, []);

  // Load latest history data for prove withdraw
  const loadHistory = useCallback(async () => {
    refetch()
      .then(async (res) => {
        const { data } = res;
        const historyList = data.list;
        const currentTxReceipt = historyList.filter((tx: any) => {
          return tx.hash === initiateHash;
        })[0];
        batchUpdate(() => {
          setCurrentTx(currentTxReceipt);
          // eslint-disable-next-line no-console
          console.log('currentTxReceipt', currentTxReceipt);
          // Set prove withdraw tx hash
          if (currentTxReceipt?.l1ProveTxHash) {
            setProveHash(currentTxReceipt?.l1ProveTxHash);
          }
          // Set prove finalize tx hash
          if (currentTxReceipt?.l1TxHash && Number(currentTxReceipt?.l1TxHash) !== 0) {
            setFinalizeHash(currentTxReceipt?.l1TxHash);
          }
        });
        // Clean up tx status cache
        const cache = await getHistoryCache();
        // Check whether the status is updated, if updated then remove cache
        if (cache) {
          const cacheStatus = cache[`${initiateHash as string}`];
          if (
            currentTxReceipt &&
            cacheStatus &&
            currentTxReceipt?.receiptsStatus > cacheStatus &&
            typeof window !== 'undefined'
          ) {
            // get updated status from backend, we can remove cache
            deleteDataFromCache(window.location.hostname, initiateHash);
            setHistoryCache(null);
          } else {
            setHistoryCache(cache);
          }
        } else {
          setHistoryCache(null);
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
        setIsConfirming(false);
      });
  }, [refetch, initiateHash]);

  useEffect(() => {
    if (isConnected && wagmiAddress && initiateHash) {
      // Start loading history immediately when the component mounts
      loadHistory();
      // Set up an interval to call loadHistory every mins
      const interval = setInterval(loadHistory, 1000 * 5);
      return () => clearInterval(interval);
    }
  }, [isConnected, wagmiAddress, loadHistory, initiateHash]);

  useEffect(() => {
    getDataFromCache(window.location.hostname, {
      cacheName: 'autoWithdraw',
      deleteCacheWhenTimeOut: false,
    }).then((res) => {
      if (res !== null) {
        setIsAutoWithdraw(res?.enable);
      } else {
        setIsAutoWithdraw(true);
      }
    });
  }, []);

  useEffect(() => {
    if (tokenType !== 'BNB') {
      getL2Allowance(tokenType)
        .then((res) => {
          // eslint-disable-next-line no-console
          console.log(tokenType + ' allowance', Number(res * Math.pow(10, -18)));
          setTokenAllowance(Number(res * Math.pow(10, -18)));
        })
        .catch((e: any) => {
          // eslint-disable-next-line no-console
          console.log('Failed to get l2 token allowance', e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenType]);

  return (
    <Container
      isOpen={isOpen}
      onClose={() => {
        handleOpen(false);
        setCurrentTx(null);
        reportEvent({ name: GA_MAP.withdrawPopupClose, data: { name: 'Close Withdraw Popup' } });
      }}
      margin={0}
      overlayProps={{
        backdropFilter: 'blur(10px)',
      }}
    >
      <ModalCloseButton color={'readable.pageButton'} />
      <Flex flexDirection={'column'} flex={1}>
        <ModalTitle>Withdrawal</ModalTitle>

        {!initiateHash && <AutoWithdrawSwitch handleAutoSwitch={setIsAutoWithdraw} />}

        <Flex
          mb={[4.5, 12]}
          flexDirection={['row', 'row']}
          justifyContent={'space-between'}
          alignItems={'center'}
          fontSize={[12, 14]}
        >
          <Box color={'readable.pageButton'} mb={[4, 0]}>
            Amount to Withdraw
          </Box>
          <Box>
            {!isLoadingPrice && !isLoadingGasPrice && !isCalcPrice && tokenPrice ? (
              <>
                <span style={{ fontWeight: '700' }}>{`${removeTrailingZero(
                  Number(withdrawVal)?.toFixed(8),
                )} ${tokenType}`}</span>{' '}
                {`(${
                  Number(Number(withdrawVal) * tokenPrice) < 0.01
                    ? '< $0.01'
                    : `$${Number(Number(withdrawVal) * tokenPrice).toLocaleString('fullwide', {
                        maximumFractionDigits: 2,
                      })}`
                })`}
              </>
            ) : (
              <Box width={16} height={16} mx={4}>
                <SpinImage>
                  <TxLoading w={16} h={16} />
                </SpinImage>
              </Box>
            )}
          </Box>
        </Flex>

        {isAutoWithdraw && (
          <AutoGasFee
            tokenPrice={bnbPrice}
            l2GasPrice={l2GasPrice}
            l1DataGas={l1DataGas}
            estimateGas={estimateGas}
          />
        )}

        <WithdrawProgress
          step={step}
          initiateHash={initiateHash}
          proveHash={proveHash}
          finalizeHash={finalizeHash}
          estimateGas={estimateGas}
          isLoadingL1GasPrice={isLoadingGasPrice || isLoadingPrice || isCalcPrice}
          bnbPrice={bnbPrice}
          l1GasPrice={l1GasPrice}
          l2GasPrice={l2GasPrice}
          l1DataGas={l1DataGas}
          historyCache={historyCache}
          isAutoWithdraw={isAutoWithdraw}
        />

        <Box>
          {step < 1 && (
            <WithdrawalCheckBox isAutoWithdraw={isAutoWithdraw} setTickBox={setTickBoxOne} />
          )}

          {initiateHash &&
          (step === 2 || step === 4) &&
          isConnected &&
          currentChain &&
          currentChain.id !== Number(env.L1_CHAIN_ID) ? (
            <SwitchNetworkButton
              id={Number(env.L1_CHAIN_ID)}
              isWithdraw={true}
              onSwitchHandler={() => {
                reportEvent({
                  name: GA_MAP.withdrawPopupSwitch,
                  data: { name: 'Withdraw Switch Network' },
                });
              }}
            />
          ) : null}

          {!initiateHash &&
          isConnected &&
          currentChain &&
          currentChain.id !== Number(env.L2_CHAIN_ID) ? (
            <SwitchNetworkButton
              id={Number(env.L2_CHAIN_ID)}
              isWithdraw={true}
              onSwitchHandler={() => {
                reportEvent({
                  name: GA_MAP.withdrawPopupSwitch,
                  data: { name: 'Withdraw Switch Network' },
                });
              }}
            />
          ) : null}

          {/* BNB withdraw */}
          {isConnected &&
          currentChain &&
          currentChain.id === Number(env.L2_CHAIN_ID) &&
          tokenType === 'BNB' &&
          step < 1 &&
          !isConfirming ? (
            isAutoWithdraw ? (
              <WithdrawButton
                disabled={!isSufficient || !withdrawVal || !tickBoxOne}
                onClick={async () => {
                  reportEvent({
                    name: GA_MAP.autoWithdrawButton,
                    data: {
                      name: 'Auto Withdrawal Button Click',
                    },
                  });
                  setIsConfirming(true);
                  try {
                    autoWithdraw(Number(withdrawVal), asset)
                      .then((res) => {
                        setInitHash(res?.hash as string);
                        setTmpCountTime(Number(Date.now()));
                        setIsConfirming(false);
                      })
                      .catch((e: any) => {
                        //eslint-disable-next-line no-console
                        console.log(e);
                        setIsConfirming(false);
                        setTmpCountTime(Number(Date.now()));
                      });
                  } catch (e) {
                    batchUpdate(() => {
                      setIsConfirming(false);
                      setTmpCountTime(Number(Date.now()));
                    });
                  }
                }}
              >
                Withdraw
              </WithdrawButton>
            ) : (
              <WithdrawButton
                disabled={!isSufficient || !withdrawVal || !tickBoxOne}
                onClick={() => {
                  reportEvent({
                    name: GA_MAP.withdrawInitClick,
                    data: { name: 'Click Init Withdrawal' },
                  });
                  try {
                    setIsConfirming(true);
                    withdraw(withdrawVal)
                      .then((res: any) => {
                        if (res) {
                          setInitHash(res as string);
                          setIsConfirming(false);
                          setTmpCountTime(Number(Date.now()));
                        } else {
                          setIsConfirming(false);
                        }
                      })
                      .catch((e: any) => {
                        //eslint-disable-next-line no-console
                        console.log(e);
                        setIsConfirming(false);
                        setTmpCountTime(Number(Date.now()));
                      });
                  } catch (e) {
                    //eslint-disable-next-line no-console
                    console.log(e);
                    setIsConfirming(false);
                    setTmpCountTime(Number(Date.now()));
                  }
                }}
              >
                Initiate Withdrawal
              </WithdrawButton>
            )
          ) : null}

          {isConnected &&
          currentChain &&
          currentChain.id === Number(env.L2_CHAIN_ID) &&
          tokenType !== 'BNB' &&
          step < 1 &&
          !isConfirming ? (
            isAutoWithdraw ? (
              tokenAllowance >= Number(withdrawVal) ? (
                <WithdrawButton
                  disabled={!isSufficient || !withdrawVal || !tickBoxOne}
                  onClick={async () => {
                    reportEvent({
                      name: GA_MAP.autoWithdrawButton,
                      data: {
                        name: 'Auto Withdrawal Button Click',
                      },
                    });
                    setIsConfirming(true);
                    try {
                      autoWithdraw(Number(withdrawVal), asset)
                        .then((res) => {
                          setInitHash(res?.hash as string);
                          setTmpCountTime(Number(Date.now()));
                          setIsConfirming(false);
                        })
                        .catch((e: any) => {
                          //eslint-disable-next-line no-console
                          console.log(e);
                          setIsConfirming(false);
                          setTmpCountTime(Number(Date.now()));
                        });
                    } catch (e) {
                      batchUpdate(() => {
                        setIsConfirming(false);
                        setTmpCountTime(Number(Date.now()));
                      });
                    }
                  }}
                >
                  Withdraw
                </WithdrawButton>
              ) : null
            ) : (
              <DepositButton
                w={'100%'}
                disabled={!tickBoxOne || !isSufficient || !withdrawVal}
                onClick={() => {
                  reportEvent({
                    name: GA_MAP.withdrawInitClick,
                    data: { name: 'Click Init ERC20 WithDraw' },
                  });
                  setIsConfirming(true);
                  if (asset && asset?.length > 0) {
                    withdrawERC20(withdrawVal, asset[0])
                      .then((res) => {
                        if (res) {
                          batchUpdate(() => {
                            setIsConfirming(false);
                            setInitHash(res);
                            setTmpCountTime(Number(Date.now()));
                          });
                        } else {
                          setIsConfirming(false);
                        }
                      })
                      .catch((e: any) => {
                        batchUpdate(() => {
                          if (e) {
                            setTxFailedMsgErc20(e?.code || e);
                          }
                          setIsConfirming(false);
                          setTmpCountTime(Number(Date.now()));
                          handleErrorShowErc20(true);
                        });
                        // eslint-disable-next-line no-console
                        console.log(e);
                      });
                  }
                }}
              >
                Initiate Withdrawal
              </DepositButton>
            )
          ) : null}

          {tokenAllowance < Number(withdrawVal) && isAutoWithdraw && tokenType !== 'BNB' ? (
            <>
              <ApproveL2TokenButton
                asset={asset}
                disabled={
                  !Number(withdrawVal) || isLoadingL2Allowance || l2BalanceVal === 0 || !tickBoxOne
                }
                amount={withdrawVal}
                allowance={tokenAllowance}
                tokenType={tokenType}
                handleAllowanceUpdate={setTokenAllowance}
              />
            </>
          ) : null}

          {isConfirming ? (
            <DepositButton w={'100%'} disabled={true} alignItems={'center'}>
              <Box w={20} h={20} mr={8}>
                <Loading
                  style={{ height: '20px' }}
                  shortroundcolor="#535458"
                  longroundcolor="#AEAFB0"
                />
              </Box>
              <Box h={20}>Confirm in Wallet</Box>
            </DepositButton>
          ) : historyCache && historyCache[initiateHash] && step !== 5 ? (
            // Waiting to prove or finalize
            <DepositButton w={'100%'} disabled={true} alignItems={'center'}>
              <Box w={20} h={20} mr={8}>
                <Loading
                  style={{ height: '20px' }}
                  shortroundcolor="#535458"
                  longroundcolor="#AEAFB0"
                />
              </Box>
              {step === 2 ? 'Proving Withdrawal' : 'Finalizing Withdrawal'}
            </DepositButton>
          ) : step === 1 || step === 3 ? (
            <>
              <Box w={'100%'} alignItems={'center'}>
                <WaitingText>
                  {currentTx?.statusChangeTimeStamp ? (
                    isAutoWithdraw ? ( // auto withdrawal waiting text
                      <EstimatedArrival
                        time={
                          step === 1
                            ? Number(
                                currentTx?.statusChangeTimeStamp +
                                  env.PROVE_WAITING_PERIOD[env.NET_ENV] +
                                  env.FINALIZE_WAITING_PERIOD[env.NET_ENV],
                              ) * 1000
                            : Number(
                                currentTx?.statusChangeTimeStamp +
                                  env.FINALIZE_WAITING_PERIOD[env.NET_ENV],
                              ) * 1000
                        }
                      />
                    ) : (
                      <ReturnAfter
                        time={
                          step === 1
                            ? Number(
                                currentTx?.statusChangeTimeStamp +
                                  env.PROVE_WAITING_PERIOD[env.NET_ENV],
                              ) * 1000
                            : Number(
                                currentTx?.statusChangeTimeStamp +
                                  env.FINALIZE_WAITING_PERIOD[env.NET_ENV],
                              ) * 1000
                        }
                        status={step}
                      />
                    )
                  ) : isAutoWithdraw ? ( // auto withdrawal waiting text
                    <EstimatedArrival
                      time={
                        step === 1
                          ? // start time from local submit time
                            tmpCountTime +
                            Number(
                              env.PROVE_WAITING_PERIOD[env.NET_ENV] +
                                env.FINALIZE_WAITING_PERIOD[env.NET_ENV],
                            ) *
                              1000
                          : Number(tmpCountTime + env.FINALIZE_WAITING_PERIOD[env.NET_ENV] * 1000)
                      }
                    />
                  ) : (
                    <ReturnAfter
                      time={
                        step === 1
                          ? tmpCountTime + Number(env.PROVE_WAITING_PERIOD[env.NET_ENV]) * 1000
                          : tmpCountTime + Number(env.FINALIZE_WAITING_PERIOD[env.NET_ENV]) * 1000
                      }
                      status={step}
                    />
                  )}
                </WaitingText>
              </Box>
              {isAutoWithdraw ? (
                <Warning justifyContent={'center'}>
                  <Box>
                    You can close this popup safely and check status in{' '}
                    <PrimaryLink
                      onClick={() => {
                        reportEvent({
                          name: GA_MAP.autoWithdrawHistory,
                          data: { name: 'Auto Popup History Link' },
                        });
                      }}
                      href="/history?type=withdraw"
                    >
                      History
                    </PrimaryLink>{' '}
                    later.
                  </Box>
                </Warning>
              ) : (
                <Warning>
                  <ColoredWarningIcon width={16} height={16} mr={4} color="scene.orange.normal" />
                  <Box>
                    The withdrawal process depends on your manual{' '}
                    <span>{step === 1 ? 'Prove' : 'Finalize'}</span> operation. Please return to the{' '}
                    <PrimaryLink
                      onClick={() => {
                        reportEvent({
                          name: GA_MAP.withdrawPopupHistoryLink,
                          data: { name: 'Popup History Link' },
                        });
                      }}
                      href="/history?type=withdraw"
                    >
                      history
                    </PrimaryLink>{' '}
                    page to <span>{step === 1 ? `Prove Withdrawal` : `Finalize Withdrawal`}</span>{' '}
                    after{' '}
                    {step === 1
                      ? env.NET_ENV === 'Mainnet'
                        ? '1-2 hours'
                        : '15 mins'
                      : env.NET_ENV === 'Mainnet'
                      ? '7 days'
                      : '30 mins'}
                    .
                  </Box>
                </Warning>
              )}
            </>
          ) : step === 5 ? (
            <WithdrawButton
              onClick={() => {
                router.push('/history?type=withdraw');
                if (isAutoWithdraw) {
                  reportEvent({
                    name: GA_MAP.autoWithdrawHistory,
                    data: { name: 'Auto Withdrawal History' },
                  });
                }
              }}
            >
              {TxStatus[step]}
            </WithdrawButton>
          ) : // Prove Button
          !isConfirming &&
            step === 2 &&
            currentChain &&
            currentChain.id === Number(env.L1_CHAIN_ID) ? (
            <ProveButton
              initiateHash={initiateHash}
              setProveHash={setProveHash}
              setTmpCountTime={setTmpCountTime}
              setIsConfirming={setIsConfirming}
              step={step}
              proveWithdraw={proveWithdraw}
              loadHistory={loadHistory}
              TxStatus={TxStatus}
            />
          ) : // Finalize Button
          !isConfirming &&
            step === 4 &&
            currentChain &&
            currentChain.id === Number(env.L1_CHAIN_ID) ? (
            <FinalizeButton
              initiateHash={initiateHash}
              setFinalizeHash={setFinalizeHash}
              setTmpCountTime={setTmpCountTime}
              setIsConfirming={setIsConfirming}
              step={step}
              finalWithdraw={finalWithdraw}
              loadHistory={loadHistory}
              TxStatus={TxStatus}
            />
          ) : null}

          {txProveFailedMsg && (
            <TxError
              title={`Prove Withdrawal Failed`}
              isOpen={showError}
              handleOpen={() => {
                batchUpdate(() => {
                  setTxProveFailedMsg('');
                  setCurrentTx(null);
                  setIsConfirming(false);
                });
                // close withdraw popup
                handleOpen(false);
                handleErrorShow(false);
              }}
              errorCode={txProveFailedMsg}
            />
          )}

          {txFinalizeFailedMsg && (
            <TxError
              title={`Finalize Withdrawal Failed`}
              isOpen={showError}
              handleOpen={() => {
                batchUpdate(() => {
                  setTxFinalizeFailedMsg('');
                  setCurrentTx(null);
                  setIsConfirming(false);
                });
                // close withdraw popup
                handleOpen(false);
                handleErrorShow(false);
              }}
              errorCode={txFinalizeFailedMsg}
            />
          )}
        </Box>
      </Flex>

      {txFailedMsg && (
        <TxError
          isOpen={showError}
          title="Transaction Failed"
          handleOpen={() => handleErrorShow(false)}
          errorCode={txFailedMsg} // txFailedMsg from useWithdraw
          clearInput={() => {
            // close error modal
            handleOpen(false);
            clearInput();
          }}
        />
      )}
    </Container>
  );
};

export const PrimaryLink = styled(Link)`
  color: ${(props: any) => props.theme.colors?.readable?.link};
  font-size: 14px;
  line-height: 18px;
  &:hover {
    color: ${(props: any) => props.theme.colors?.scene.primary.normal};
  }
`;

export const Warning = styled(Flex)`
  flex-direction: row;
  margin-top: 8px;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: ${(props: any) => props.theme.colors.readable.pageButton};
  span {
    font-weight: 700;
  }
`;

export const WaitingText = styled.div`
  line-height: 20px;
  text-align: center;
  padding: 16px 24px;
  background: #fbe0c6;
  font-weight: 500;
  height: 48px;
  border-radius: 8px;
  color: ${(props: any) => props.theme.colors?.scene?.orange.normal};
`;

export const Container = styled(Modal)`
  border-radius: 12px;
  box-shadow: ${(props: any) => props.theme.colors.shadows?.normal};
  justify-content: center;
  align-items: center;
  .ui-modal-content {
    padding: 48px 24px;
    width: 542px;
    background: ${(props: any) => props.theme.colors.readable.normal};
    color: ${(props: any) => props.theme.colors.bg?.card};
    ${mobileMedia} {
      margin: 0px;
      padding: 24px;
      max-width: 542px !important;
      width: 100%;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
  ${mobileMedia} {
    top: auto !important;
    border-radius: 0px;
    &::after {
      height: 0px;
    }
  }
`;

export const ModalTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  margin-bottom: 24px;
  ${mobileMedia} {
    margin-bottom: 16px;
  }
`;

export const WithdrawButton = styled(Button)`
  line-height: 20px;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  color: ${(props: any) => props.theme.colors.bg?.card};
  &[disabled] {
    &,
    &:hover {
      color: ${(props: any) => props.theme.colors.readable?.disabled};
      background: ${(props: any) => props.theme.colors.bg?.bottom};
      opacity: 1;
    }
  }
`;
