import { Flex, ModalCloseButton, Box } from '@node-real/uikit';
import { ColoredWarningIcon } from '@node-real/icons';
import { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import Link from 'next/link';

import { GA_MAP, reportEvent } from '../../utils/ga';
import * as env from '../../env';
import { DepositButton } from '../Deposit';
import {
  addDataIntoCache,
  batchUpdate,
  deleteDataFromCache,
  removeTrailingZero,
} from '../../utils';
import { Loading, SpinImage } from '../Loading';
import { useGetGasPrice, useL1Withdraw, usePausedStatus } from '../../hooks';
import { TxLoading, WithdrawProgress } from './WithdrawProgress';
import { TxError } from './TxError';
import { Warning, WaitingText, Container, ModalTitle, WithdrawButton } from './ConfirmWithdraw';
import { ReturnAfter } from '../ongoing/ReturnAfter';
import { EstimatedArrival } from '../ongoing/EstimatedArrival';
import { AutoGasFee } from './gasFee/AutoGasFee';

const TxStatus = [
  '',
  env.NET_ENV === 'Testnet'
    ? `Return in 15 mins to Prove Withdrawal`
    : `Return in 2 hrs to Prove Withdrawal`,
  'Prove Withdrawal',
  env.NET_ENV === 'Testnet'
    ? 'Return in 30 mins to Finalize Withdrawal'
    : 'Return in 7 days to Finalize Withdrawal',
  'Finalize Withdrawal',
  'View in History',
];

interface ConfirmPopupProps {
  isOpen: boolean;
  handleOpen: (show: boolean) => void;
  bnbPrice: number;
  tokenPrice: number;
  tokenName: string;
  initiateWithdrawHash: string;
  proveWithdrawHash?: string;
  finalizeWithdrawHash?: string;
  historyCache: { [key: string]: string } | null;
  historyList: { [key: string]: any }[] | null;
  isInitConfirming: boolean;
  setButtonSubmit: (status: number | null) => void;
  buttonSubmit: number | null;
  withdrawVal?: number;
  isHistoryPage?: boolean;
}

export const HistoryConfirmWithdraw = (props: ConfirmPopupProps) => {
  const {
    isOpen,
    handleOpen,
    bnbPrice,
    tokenPrice,
    tokenName,
    initiateWithdrawHash,
    proveWithdrawHash,
    finalizeWithdrawHash,
    historyCache,
    historyList,
    isInitConfirming,
    setButtonSubmit,
    buttonSubmit,
    withdrawVal,
    isHistoryPage = true,
  } = props;

  const currentTx = historyList?.filter((history) => history.hash === initiateWithdrawHash)[0];

  const { isLoadingGasPrice, getGasPrice } = useGetGasPrice();

  const [proveHash, setProveHash] = useState(proveWithdrawHash || currentTx?.l1ProveTxHash || '');
  const [disableWithdraw, setDisableWithdraw] = useState(false); // disable withdraw button
  const [finalizeHash, setFinalizeHash] = useState(
    finalizeWithdrawHash || (currentTx?.l1TxHash && Number(currentTx?.l1TxHash) !== 0)
      ? currentTx?.l1TxHash
      : '',
  );
  const {
    proveWithdraw,
    finalWithdraw,
    txProveFailedMsg,
    setTxProveFailedMsg,
    txFinalizeFailedMsg,
    setTxFinalizeFailedMsg,
    showError,
    handleErrorShow,
  } = useL1Withdraw();
  const router = useRouter();
  const [isConfirming, setIsConfirming] = useState(false); // confirm withdraw in wallet
  const [l1GasPrice, setL1GasPrice] = useState(0);
  const { loadPausedStatus } = usePausedStatus();

  /**
   * step 0 - Not yet initiate withdrawal
   * step 1 - Initiate withdrawal completed, waiting to prove - Return in 2 hrs to Prove Withdrawal
   * step 2 - Ready to prove
   * step 3 - Prove withdrawal completed, waiting to finalize - Return in 7 days to Finalize Withdrawal
   * step 4 - Ready to finalize
   * step 5 - Finalize withdrawal completed
   */
  const step = currentTx && Number(currentTx.receiptsStatus) ? Number(currentTx.receiptsStatus) : 5;

  const getL1GasPrice = useCallback(async () => {
    const l1GasPrice = await getGasPrice(env.L1_RPC_URL);
    setL1GasPrice(Number(l1GasPrice));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getL1GasPrice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update prove hash
  useEffect(() => {
    if (proveWithdrawHash) {
      setProveHash(proveWithdrawHash);
    } else if (currentTx?.l1ProveTxHash) {
      setProveHash(currentTx?.l1ProveTxHash);
    }
  }, [proveWithdrawHash, currentTx?.l1ProveTxHash]);

  // Update prove hash
  useEffect(() => {
    if (finalizeWithdrawHash) {
      setFinalizeHash(finalizeWithdrawHash);
    } else if (currentTx?.l1TxHash && Number(currentTx?.l1TxHash) !== 0) {
      setFinalizeHash(currentTx?.l1TxHash);
    }
  }, [finalizeWithdrawHash, currentTx?.l1TxHash]);

  useEffect(() => {
    try {
      // delete cache if status updated
      if (
        historyCache &&
        initiateWithdrawHash in historyCache &&
        (Number(currentTx?.receiptsStatus) === 3 || Number(currentTx?.receiptsStatus) === 5)
      ) {
        const cacheStatus = historyCache[`${initiateWithdrawHash as string}`];
        if (
          currentTx &&
          cacheStatus &&
          currentTx?.receiptsStatus > cacheStatus &&
          typeof window !== 'undefined'
        ) {
          // get updated status from backend, we can remove cache
          deleteDataFromCache(window.location.hostname, currentTx?.hash);
          batchUpdate(() => {
            setDisableWithdraw(false);
            setButtonSubmit(null);
          });
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }, [currentTx, initiateWithdrawHash, historyCache, setButtonSubmit]);

  useEffect(() => {
    if (!(historyCache && historyCache[initiateWithdrawHash])) {
      setDisableWithdraw(false);
    }
  }, [historyCache, initiateWithdrawHash]);

  return (
    <Container
      isOpen={isOpen}
      onClose={() => {
        handleOpen(false);
        setButtonSubmit(null);
      }}
      margin={0}
      overlayProps={{
        backdropFilter: 'blur(10px)',
      }}
    >
      <ModalCloseButton color={'readable.pageButton'} />
      <Flex flexDirection={'column'} flex={1}>
        <ModalTitle>Withdrawal</ModalTitle>

        <Flex
          mb={[4.5, 12]}
          flexDirection={['row']}
          justifyContent={'space-between'}
          alignItems={'center'}
          fontSize={[12, 14]}
        >
          <Box color={'readable.pageButton'} mb={[4, 0]}>
            Amount to Withdraw:
          </Box>
          <Box>
            {tokenPrice && withdrawVal ? (
              <>
                <span style={{ fontWeight: '700' }}>{`${removeTrailingZero(
                  Number(withdrawVal)?.toFixed(8),
                )} ${tokenName}`}</span>{' '}
                {`(${
                  Number(Number(withdrawVal) * tokenPrice) < 0.01
                    ? '< $0.01'
                    : `$${Number(Number(withdrawVal) * tokenPrice).toLocaleString('fullwide', {
                        maximumFractionDigits: 2,
                      })}`
                })`}
              </>
            ) : (
              <Box width={16} height={16} mx={4}>
                <SpinImage>
                  <TxLoading w={16} h={16} />
                </SpinImage>
              </Box>
            )}
          </Box>
        </Flex>

        {currentTx?.isAuto && (
          <AutoGasFee
            tokenPrice={bnbPrice}
            l2GasPrice={currentTx?.gasPrice * Math.pow(10, -18)}
            l1DataGas={Number(currentTx?.l1Fee) * Math.pow(10, -18)}
            estimateGas={currentTx?.gasUsed}
          />
        )}

        <WithdrawProgress
          step={step || 3}
          isLoadingL1GasPrice={isLoadingGasPrice}
          initiateHash={initiateWithdrawHash}
          proveHash={proveHash}
          finalizeHash={finalizeHash}
          bnbPrice={bnbPrice}
          l1GasPrice={l1GasPrice}
          historyCache={historyCache}
          isAutoWithdraw={currentTx?.isAuto || false}
        />

        <Box>
          {isConfirming || isInitConfirming ? (
            <DepositButton w={'100%'} disabled={true} alignItems={'center'}>
              <Box w={20} h={20} mr={8}>
                <Loading
                  style={{ height: '20px' }}
                  shortroundcolor="#535458"
                  longroundcolor="#AEAFB0"
                />
              </Box>
              <Box h={20}>Confirm in Wallet</Box>
            </DepositButton>
          ) : step === 2 || step === 4 ? (
            currentTx?.isAuto ? ( // auto withdraw text
              <WaitingText>
                <EstimatedArrival
                  time={
                    Number(
                      currentTx?.statusChangeTimeStamp + env.FINALIZE_WAITING_PERIOD[env.NET_ENV],
                    ) * 1000
                  }
                />
              </WaitingText>
            ) : historyCache && historyCache[initiateWithdrawHash] ? (
              <Box w={'100%'} alignItems={'center'}>
                <DepositButton w={'100%'} disabled={true} alignItems={'center'}>
                  <Box w={20} h={20} mr={8}>
                    <Loading
                      style={{ height: '20px' }}
                      shortroundcolor="#535458"
                      longroundcolor="#AEAFB0"
                    />
                  </Box>
                  {step === 4 && proveWithdrawHash ? `Finalizing Withdrawal` : `Proving Withdrawal`}
                </DepositButton>
              </Box>
            ) : null
          ) : step === 1 || step === 3 ? (
            <>
              <Box w={'100%'} alignItems={'center'}>
                <WaitingText>
                  {currentTx?.statusChangeTimeStamp ? (
                    currentTx?.isAuto ? ( // auto withdraw text
                      <EstimatedArrival
                        time={
                          step === 1
                            ? Number(
                                currentTx?.statusChangeTimeStamp +
                                  env.PROVE_WAITING_PERIOD[env.NET_ENV] +
                                  env.FINALIZE_WAITING_PERIOD[env.NET_ENV],
                              ) * 1000
                            : Number(
                                currentTx?.statusChangeTimeStamp +
                                  env.FINALIZE_WAITING_PERIOD[env.NET_ENV],
                              ) * 1000
                        }
                      />
                    ) : (
                      <ReturnAfter
                        time={
                          step === 1
                            ? Number(
                                currentTx?.statusChangeTimeStamp +
                                  env.PROVE_WAITING_PERIOD[env.NET_ENV],
                              ) * 1000
                            : Number(
                                currentTx?.statusChangeTimeStamp +
                                  env.FINALIZE_WAITING_PERIOD[env.NET_ENV],
                              ) * 1000
                        }
                        status={step}
                      />
                    )
                  ) : (
                    TxStatus[step]
                  )}
                </WaitingText>
              </Box>
              {currentTx?.isAuto ? (
                <Warning justifyContent={'center'}>
                  <Box>
                    You can close this popup safely and check status in{' '}
                    {isHistoryPage ? (
                      <PrimaryLink onClick={() => handleOpen(false)}>History</PrimaryLink>
                    ) : (
                      <RedirectLink href="/history?type=withdraw">History</RedirectLink>
                    )}{' '}
                    later.
                  </Box>
                </Warning>
              ) : (
                <Warning>
                  <ColoredWarningIcon width={16} height={16} mr={4} color="scene.orange.normal" />
                  <Box>
                    The withdrawal process depends on your manual{' '}
                    <span>{step === 1 ? 'Prove' : 'Finalize'}</span> operation. Please return to the{' '}
                    <PrimaryLink onClick={() => handleOpen(false)}>history</PrimaryLink> page to{' '}
                    <span>{step === 1 ? `Prove Withdrawal` : `Finalize Withdrawal`}</span> after{' '}
                    {step === 1
                      ? env.NET_ENV === 'Mainnet'
                        ? '1-2 hours'
                        : '15 mins'
                      : env.NET_ENV === 'Mainnet'
                      ? '7 days'
                      : '30 mins'}
                    .
                  </Box>
                </Warning>
              )}
            </>
          ) : step === 5 ? (
            <WithdrawButton
              onClick={() => {
                if (currentTx) {
                  handleOpen(false);
                } else {
                  router.push('/history?type=withdraw');
                }
              }}
            >
              {TxStatus[step]}
            </WithdrawButton>
          ) : null}

          {/* Prove Button */}
          {!isConfirming &&
            !isInitConfirming &&
            step === 2 &&
            !currentTx?.isAuto &&
            !disableWithdraw &&
            buttonSubmit === null &&
            (!historyCache || (historyCache && !historyCache[initiateWithdrawHash])) && (
              <WithdrawButton
                withdrawtype="prove"
                onClick={async () => {
                  reportEvent({ name: GA_MAP.prove, data: { name: 'Prove Withdraw' } });
                  const status = await loadPausedStatus(false);
                  setIsConfirming(true);
                  try {
                    if (!status) {
                      proveWithdraw(initiateWithdrawHash)
                        .then(async (res) => {
                          if (res) {
                            batchUpdate(() => {
                              setButtonSubmit(1);
                              setProveHash(res);
                            });
                            if (typeof window !== 'undefined') {
                              // Add temp status into cache
                              addDataIntoCache(window.location.hostname, {
                                [`${initiateWithdrawHash}`]: step,
                              })
                                .then(() => {
                                  setDisableWithdraw(true);
                                })
                                .catch((e: any) => {
                                  // eslint-disable-next-line no-console
                                  console.log(e);
                                  setDisableWithdraw(true);
                                });
                            }
                          }
                          setIsConfirming(false);
                        })
                        .catch((e: any) => {
                          // eslint-disable-next-line no-console
                          console.log(e);
                          setIsConfirming(false);
                        });
                    } else {
                      setIsConfirming(false);
                    }
                  } catch (e) {
                    // eslint-disable-next-line no-console
                    console.log(e);
                    setIsConfirming(false);
                  }
                }}
              >
                {TxStatus[step]}
              </WithdrawButton>
            )}

          {/* Finalize Button */}
          {!isConfirming &&
            !isInitConfirming &&
            step === 4 &&
            !currentTx?.isAuto &&
            !disableWithdraw &&
            buttonSubmit === null &&
            (!historyCache || (historyCache && !historyCache[initiateWithdrawHash])) && (
              <WithdrawButton
                withdrawtype="finalize"
                onClick={async () => {
                  reportEvent({ name: GA_MAP.finalize, data: { name: 'Finalize Withdraw' } });
                  const status = await loadPausedStatus(false);
                  try {
                    if (!status) {
                      setIsConfirming(true);
                      finalWithdraw(initiateWithdrawHash)
                        .then(async (res) => {
                          if (res) {
                            batchUpdate(() => {
                              setButtonSubmit(2);
                              setFinalizeHash(res);
                            });
                            if (typeof window !== 'undefined') {
                              // Add temp status into cache
                              await addDataIntoCache(window.location.hostname, {
                                [`${initiateWithdrawHash}`]: step,
                              });
                              setDisableWithdraw(true);
                            }
                          }
                          setIsConfirming(false);
                        })
                        .catch((e: any) => {
                          // eslint-disable-next-line no-console
                          console.log(e);
                          setIsConfirming(false);
                        });
                    } else {
                      setIsConfirming(false);
                    }
                  } catch (e) {
                    //eslint-disable-next-line no-console
                    console.log(e);
                    setIsConfirming(false);
                  }
                }}
              >
                {TxStatus[step]}
              </WithdrawButton>
            )}

          {txProveFailedMsg && (
            <TxError
              title={`Prove Withdrawal Failed`}
              isOpen={showError}
              handleOpen={() => {
                handleOpen(false);
                setTxProveFailedMsg('');
                setIsConfirming(false);
                handleErrorShow(false);
              }}
              errorCode={txProveFailedMsg}
            />
          )}

          {txFinalizeFailedMsg && (
            <TxError
              title={`Finalize Withdrawal Failed`}
              isOpen={showError}
              handleOpen={() => {
                handleOpen(false);
                setTxFinalizeFailedMsg('');
                setIsConfirming(false);
                handleErrorShow(false);
              }}
              errorCode={txFinalizeFailedMsg}
            />
          )}
        </Box>
      </Flex>
    </Container>
  );
};

const PrimaryLink = styled(Box)`
  color: ${(props: any) => props.theme.colors?.readable?.link};
  font-size: 14px;
  display: inline-block;
  line-height: 18px;
  cursor: pointer;
  &:hover {
    color: ${(props: any) => props.theme.colors?.scene.primary.normal};
  }
`;

const RedirectLink = styled(Link)`
  color: ${(props: any) => props.theme.colors?.readable?.link};
  font-size: 14px;
  line-height: 18px;
  &:hover {
    color: ${(props: any) => props.theme.colors?.scene.primary.normal};
  }
`;
