import { formatLocalTime } from '../../utils';
interface ReturnAfterProps {
  time: number;
  status: number;
}

export const ReturnAfter = (props: ReturnAfterProps) => {
  const { time, status } = props;

  if (time < Date.now()) {
    return (
      <div>
        {status === 3 ? 'Waiting to Finalize (almost there)' : 'Waiting to Prove (almost there)'}
      </div>
    );
  }
  return (
    <div>
      Return after {formatLocalTime(time, 'YYYY-M-DD HH:mm')}
      {status === 3 ? ' to Finalize' : ' to Prove'}
    </div>
  );
};
