import { useCallback, useState } from 'react';
import { useResponsive } from '@op-bridge/bridge-core';

import { useModal } from './useModal';

export const useWithdrawConfirm = () => {
  const [showWithdrawalConfirm, setShowWithdrawalConfirm] = useState(false);
  const modalData = useModal();

  const { isMobile } = useResponsive();
  const handleWithdrawToggle = useCallback(
    (show?: boolean) => {
      if (!!show) {
        setShowWithdrawalConfirm(show);
        if (!isMobile) {
          // document.body.style.overflow = 'hidden';
        }
      } else {
        setShowWithdrawalConfirm((preState) => !preState);
        document.body.style.overflow = '';
      }
    },
    [isMobile],
  );

  const handleWithdrawConfirmShow = useCallback((show: boolean) => {
    if (show) {
      handleWithdrawToggle(true);
      modalData.modalDispatch({ type: 'OPEN' });
    } else {
      handleWithdrawToggle(false);
      modalData.modalDispatch({ type: 'CLOSE' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { showWithdrawalConfirm, handleWithdrawToggle, handleWithdrawConfirmShow };
};
