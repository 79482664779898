import React, { useCallback, useEffect, useState } from 'react';
import { Box, Flex, Tooltip, useOutsideClick } from '@node-real/uikit';
import { batchUpdate } from '@op-bridge/bridge-core';
import { ColoredInfoIcon } from '@node-real/icons';
import styled from '@emotion/styled';

import { useAutoWithdraw } from '../../../hooks';
import { SpinImage } from '../../Loading';
import { TxLoading } from '../WithdrawProgress';
import { removeTrailingZero } from '../../../utils';
import { TOKEN_SYMBOL } from '../../../env';
import { GA_MAP, reportEvent } from '../../../utils/ga';

interface AutoGasFeeProps {
  tokenPrice: number;
  l2GasPrice: number;
  l1DataGas: number;
  estimateGas: number;
}
export const AutoGasFee = (props: AutoGasFeeProps) => {
  const { tokenPrice, l2GasPrice, l1DataGas, estimateGas } = props;
  const { getDelegationFee } = useAutoWithdraw();
  const [autoGasFee, setAutoGasFee] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const ref = React.useRef();

  const handleModalOpen = useCallback(() => {
    setShowTooltip((pre) => !pre);
  }, []);

  useOutsideClick({
    ref: ref as any,
    handler: () => setShowTooltip(false),
  });

  const onOpen = useCallback(() => {
    reportEvent({
      name: GA_MAP.autoWithdrawGasInfo,
      data: {
        name: 'Auto Withdrawal Gas Info',
      },
    });
    handleModalOpen();
  }, [handleModalOpen]);

  useEffect(() => {
    setIsLoading(true);
    try {
      getDelegationFee().then((res: any) => {
        // eslint-disable-next-line no-console
        console.log('Delegation fee' + res);
        batchUpdate(() => {
          setAutoGasFee(Number(res));
          setIsLoading(false);
        });
      });
    } catch (error) {
      setIsLoading(false);
    }
  }, [getDelegationFee]);

  return (
    <Flex
      mb={12}
      flexDirection={['row']}
      justifyContent={'space-between'}
      alignItems={'center'}
      fontSize={[12, 14]}
    >
      <Flex flexDirection={'row'} alignItems={'center'} color={'readable.pageButton'} mb={[4, 0]}>
        Auto-Withdrawal Gas Fee
        <Tooltip
          isOpen={showTooltip}
          padding={'12px 16px'}
          color={'bg.card'}
          background={'readable.normal'}
          borderColor={'#fff'}
          content={
            <TooltipContent>
              The total gas fee for all three steps will be collected together once I initiate the
              withdrawal.
            </TooltipContent>
          }
          maxWidth={400}
          placement="bottom"
          right={0}
          boxShadow="0px 4px 24px 0px rgba(0, 0, 0, 0.08)"
        >
          <ColoredInfoIcon
            ref={ref as any}
            onClick={onOpen}
            width={16}
            height={16}
            ml={4}
            cursor={'pointer'}
            color={'readable.secondary'}
            _hover={{ color: 'scene.primary.active' }}
          />
        </Tooltip>
      </Flex>
      <Box>
        {!isLoading ? (
          <>
            <span style={{ fontWeight: '700' }}>
              {autoGasFee && Number(autoGasFee) < 0.00000001
                ? `< 0.00000001 ${TOKEN_SYMBOL}`
                : `${removeTrailingZero(
                    Number((autoGasFee as number) + estimateGas * l2GasPrice + l1DataGas)?.toFixed(
                      8,
                    ),
                  )} ${TOKEN_SYMBOL}`}
            </span>{' '}
            {`(${
              Number(
                Number((autoGasFee as number) + estimateGas * l2GasPrice + l1DataGas) * tokenPrice,
              ) < 0.01
                ? '< $0.01'
                : `$${Number(
                    Number((autoGasFee as number) + estimateGas * l2GasPrice + l1DataGas) *
                      tokenPrice,
                  ).toLocaleString('fullwide', {
                    maximumFractionDigits: 2,
                  })}`
            })`}
          </>
        ) : (
          <Box width={16} height={16} mx={4}>
            <SpinImage>
              <TxLoading w={16} h={16} />
            </SpinImage>
          </Box>
        )}
      </Box>
    </Flex>
  );
};

const TooltipContent = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  color: ${(props: any) => props.theme.colors.bg.card};
  font-family: Space Grotesk;
  border-radius: 4px;
  background: #fff;
  padding: 0;
`;
