import styled from '@emotion/styled';
import { Checkbox, Flex } from '@node-real/uikit';

import * as env from '../../../env';
import { reportEvent, GA_MAP } from '../../../utils/ga';

interface WithdrawalCheckBoxProps {
  isAutoWithdraw: boolean;
  setTickBox: (isTicked: boolean) => void;
}

export const WithdrawalCheckBox = (props: WithdrawalCheckBoxProps) => {
  const { isAutoWithdraw, setTickBox } = props;

  return (
    <Flex flexDirection={'column'} mb={[8, 24]}>
      <StyledCheckbox
        mb={8}
        spacing={8}
        onChange={(e: any) => {
          try {
            if (e.target.checked === true) {
              if (isAutoWithdraw) {
                reportEvent({
                  name: GA_MAP.autoWithdrawCheckboxClick,
                  data: { name: 'Auto Withdraw Tick Checkbox' },
                });
              } else {
                reportEvent({
                  name: GA_MAP.withdrawPopupCheckBox,
                  data: { name: 'Tick Checkbox' },
                });
              }
              setTickBox(true);
            } else {
              setTickBox(false);
            }
          } catch (error) {
            setTickBox(false);
          }
        }}
      >
        {env.NET_ENV === 'Mainnet' ? (
          !isAutoWithdraw ? (
            <>
              I understand that I must return after <span>1-2 hours</span> and manually{' '}
              <span>Prove Withdrawal</span> before the 7-day withdrawal timer starts.
            </>
          ) : (
            <>
              I understand it will take roughly <span>7 days</span> to complete a withdrawal, and
              the total gas fee for all three steps will be collected together once I initiate the
              withdrawal.
            </>
          )
        ) : !isAutoWithdraw ? (
          <>
            I understand that I must return after <span>15 minutes</span> and manually{' '}
            <span>Prove Withdrawal</span> before the 30-minute withdrawal timer starts.
          </>
        ) : (
          <>
            I understand it will take roughly <span>45 mins</span> to complete a withdrawal, and the
            total gas fee for all three steps will be collected together once I initiate the
            withdrawal.
          </>
        )}
      </StyledCheckbox>
    </Flex>
  );
};

const StyledCheckbox = styled(Checkbox)`
  .ui-checkbox-label {
    font-size: 12px;
    color: ${(props: any) => props.theme.colors?.bg.card};
    font-weight: 500;
  }
  &.ui-checkbox {
    align-items: flex-start;
  }
  .ui-checkbox-control {
    width: 18px;
    flex: 1 0 18px;
    &[data-checked] {
      border: none;
      svg path {
        stroke: ${(props: any) => props.theme.colors?.bg.card};
        fill: ${(props: any) => props.theme.colors?.bg.card};
      }
    }
    &:hover {
      border-color: ${(props: any) => props.theme.colors?.scene.primary.normal};
    }
  }
  svg {
    width: 18px;
  }
  span {
    font-weight: 700;
  }
`;
