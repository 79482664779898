import { Icon, IconProps } from '@node-real/icons';

export const USDT = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
        fill="#26A17B"
      />
      <path
        d="M11.083 10.9968C11.0187 10.9968 10.6489 11.0289 9.86114 11.0289C9.21806 11.0289 8.78397 11.0129 8.6232 10.9968C6.19555 10.9003 4.36275 10.4662 4.36275 9.96785C4.36275 9.45338 6.17947 9.03537 8.6232 8.92283V10.5788C8.78397 10.5949 9.23413 10.6109 9.87722 10.6109C10.6328 10.6109 11.0187 10.5788 11.083 10.5788V8.92283C13.5267 9.03537 15.3435 9.45338 15.3435 9.96785C15.3435 10.4662 13.5267 10.9003 11.083 10.9968ZM11.083 8.74598V7.26688H14.4914V5H5.23092V7.26688H8.63928V8.74598C5.874 8.8746 3.80005 9.42122 3.80005 10.0804C3.80005 10.7396 5.874 11.2862 8.63928 11.4148V16.1736H11.0991V11.4148C13.8644 11.2862 15.9383 10.7396 15.9383 10.0804C15.9222 9.42122 13.8483 8.8746 11.083 8.74598Z"
        fill="white"
      />
    </Icon>
  );
};
