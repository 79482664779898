import { useEffect, useState, useCallback } from 'react';
import { useBalance, useAccount } from 'wagmi';
import { ethers, Contract } from 'ethers';

import * as env from '../env';
import { batchUpdate, getEtherFormatValue } from '../utils';
import { useGetTokenList } from './useGetTokenList';

export const useChainBalance = (tokenName: string = 'BNB') => {
  const { address, isConnected } = useAccount();

  const [l1BalanceVal, setL1BalanceVal] = useState(0);
  const [l2BalanceVal, setL2BalanceVal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingContract, setIsLoadingContract] = useState(false);
  const { tokensList, isLoading: isTokenLoading } = useGetTokenList();
  const updateContractAddress = useCallback(
    async (tokenName: string) => {
      if (
        address &&
        (env.NET_ENV === 'Testnet' || (env.NET_ENV === 'Mainnet' && !isTokenLoading))
      ) {
        const asset = tokensList.filter((asset) => asset.symbol === tokenName);
        if (Array.isArray(asset) && asset.length > 0) {
          setIsLoadingContract(true);
          const l1Address = asset[0]?.l1Address;
          const l2Address = asset[0]?.l2Address;
          const ABI = asset[0]?.ABI;

          // Prevent getting errors without switching to correct network
          const l1RpcProvider = new ethers.providers.JsonRpcProvider(env.L1_RPC_URL, 'any');
          const l2RpcProvider = new ethers.providers.JsonRpcProvider(env.L2_RPC_URL, 'any');
          const l1ERC20 = new ethers.Contract(l1Address, ABI, l1RpcProvider.getSigner(address));
          const l2ERC20 = new ethers.Contract(l2Address, ABI, l2RpcProvider.getSigner(address));
          setIsLoadingContract(false);
          return {
            l1Contract: l1ERC20,
            l2Contract: l2ERC20,
          };
        } else {
          batchUpdate(() => {
            setIsLoadingContract(false);
          });
        }
      }
    },
    [address, tokensList, isTokenLoading],
  );

  const {
    data: l1balance,
    refetch: L1BalanceRefetch,
    isLoading: l1Loading,
  } = useBalance({
    address: address,
    chainId: Number(env.L1_CHAIN_ID),
  });
  const {
    data: l2balance,
    refetch: L2BalanceRefetch,
    isLoading: l2Loading,
  } = useBalance({
    address: address,
    chainId: Number(env.L2_CHAIN_ID),
  });

  const getTokenBalance = useCallback(
    async (enableLoading: boolean, tokenName: string) => {
      // update contract instance
      updateContractAddress(tokenName).then(
        async (res: { l1Contract: Contract | null; l2Contract: Contract } | undefined) => {
          try {
            if (res) {
              const { l1Contract, l2Contract } = res;
              if (address && l1Contract && l2Contract) {
                if (enableLoading) {
                  setIsLoading(true);
                }
                const l1BalanceObj = await l1Contract.balanceOf(address);
                const l2BalanceObj = await l2Contract.balanceOf(address);

                const l1Balance = await getEtherFormatValue(l1BalanceObj);
                const l2Balance = await getEtherFormatValue(l2BalanceObj);

                // console.log(Number(l1Balance), Number(l2Balance));
                batchUpdate(() => {
                  setL1BalanceVal(Number(l1Balance));
                  setL2BalanceVal(Number(l2Balance));
                  if (enableLoading) {
                    setIsLoading(false);
                  }
                });
              }
            }
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
          }
        },
      );
    },
    [address, updateContractAddress],
  );

  /**
   * Get L1 and L2 initial balance
   */
  useEffect(() => {
    if (tokenName === 'BNB') {
      if (l1balance) {
        setL1BalanceVal(Number(l1balance.formatted));
      }
      if (l2balance) {
        setL2BalanceVal(Number(l2balance.formatted));
      }
    } else {
      getTokenBalance(true, tokenName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [l1balance, l2balance, tokenName]);

  /**
   * Update L1 and L2 balance
   */
  const updateBalance = useCallback(async () => {
    try {
      if (tokenName === 'BNB') {
        const L2B = await L2BalanceRefetch();
        const L1B = await L1BalanceRefetch();
        batchUpdate(() => {
          setL1BalanceVal(Number(L1B.data?.formatted));
          setL2BalanceVal(Number(L2B.data?.formatted));
        });
      } else {
        getTokenBalance(true, tokenName);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenName, address]);

  /**
   * Get latest balance every 5s
   */
  useEffect(() => {
    let mount = true;
    if (isConnected && !l1Loading && !l2Loading && !isLoading) {
      const requestAnimationFrameTimer = <RetT extends any>(
        fn: (kk: string) => Promise<RetT>,
        time: number = 0,
      ) => {
        let now = Date.now;
        let startTime = now();
        let endTime = startTime;
        let uid;
        let timer = (tokenName: string) => {
          if (mount) {
            uid = requestAnimationFrame(() => {
              timer(tokenName);
            });
          }
          endTime = now();
          if (endTime - startTime >= time) {
            startTime = now();
            endTime = startTime;
            fn(tokenName);
          }
        };

        uid = requestAnimationFrame(() => {
          timer(tokenName);
        });
        return uid;
      };

      requestAnimationFrameTimer(async () => await updateBalance(), 5000);
      return () => {
        mount = false;
      };
    }
  }, [isConnected, l1Loading, l2Loading, tokenName, updateBalance, isLoading]);

  return {
    l1balance,
    l2balance,
    l1BalanceVal,
    l2BalanceVal,
    L1BalanceRefetch,
    L2BalanceRefetch,
    updateBalance,
    setL1BalanceVal,
    setL2BalanceVal,
    isLoading: isLoading || isLoadingContract,
  };
};
