import styled from '@emotion/styled';
import { Flex } from '@node-real/uikit';
import { ColoredWarningIcon } from '@node-real/icons';

export const ButtonWarning = ({ text }: { text: string }) => {
  return (
    <Warning>
      <ColoredWarningIcon width={16} height={16} color="scene.orange.normal" />
      {text}
    </Warning>
  );
};

const Warning = styled(Flex)`
  flex-direction: row;
  gap: 4px;
  grid-gap: 4px;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: ${(props: any) => props.theme.colors.readable.secondary};
`;
