import {
  USDT_ADDRESS_L1,
  USDT_ADDRESS_L2,
  BTC_ADDRESS_L1,
  BTC_ADDRESS_L2,
  ETH_ADDRESS_L1,
  ETH_ADDRESS_L2,
  BUSD_ADDRESS_L1,
  BUSD_ADDRESS_L2,
  USDC_ADDRESS_L1,
  USDC_ADDRESS_L2,
  DAI_ADDRESS_L1,
  DAI_ADDRESS_L2,
  NET_ENV,
} from '../env';
import ABI from '../base/tokenABI/tokenABI.json';
import { useAppSelector } from '../store';

export const useGetTokenList = () => {
  const tokenList = useAppSelector((state) => state.base.tokenList);

  const assetList = [
    {
      name: 'BNB',
      symbol: 'BNB',
      decimals: '18',
      logoURI: '',
      l1Address: '',
      l2Address: '',
      ABI: ABI,
      extensions: {
        officialBridgeStatus: '',
      },
    },
  ];

  if (NET_ENV === 'Testnet') {
    assetList.push(
      {
        name: 'BTCB',
        symbol: 'BTCB',
        logoURI: '',
        decimals: '18',
        l1Address: BTC_ADDRESS_L1,
        l2Address: BTC_ADDRESS_L2,
        ABI: ABI,
        extensions: {
          officialBridgeStatus: '',
        },
      },
      {
        name: 'ETH',
        symbol: 'ETH',
        logoURI: '',
        decimals: '18',
        l1Address: ETH_ADDRESS_L1,
        l2Address: ETH_ADDRESS_L2,
        ABI: ABI,
        extensions: {
          officialBridgeStatus: '',
        },
      },
      {
        name: 'BUSD',
        symbol: 'BUSD',
        logoURI: '',
        decimals: '18',
        l1Address: BUSD_ADDRESS_L1,
        l2Address: BUSD_ADDRESS_L2,
        ABI: ABI,
        extensions: {
          officialBridgeStatus: '',
        },
      },
      {
        name: 'USDT',
        symbol: 'USDT',
        logoURI: '',
        decimals: '18',
        l1Address: USDT_ADDRESS_L1,
        l2Address: USDT_ADDRESS_L2,
        ABI: ABI,
        extensions: {
          officialBridgeStatus: '',
        },
      },
      {
        name: 'USDC',
        symbol: 'USDC',
        logoURI: '',
        decimals: '18',
        l1Address: USDC_ADDRESS_L1,
        l2Address: USDC_ADDRESS_L2,
        ABI: ABI,
        extensions: {
          officialBridgeStatus: '',
        },
      },
      {
        name: 'DAI',
        symbol: 'DAI',
        logoURI: '',
        decimals: '18',
        l1Address: DAI_ADDRESS_L1,
        l2Address: DAI_ADDRESS_L2,
        ABI: ABI,
        extensions: {
          officialBridgeStatus: '',
        },
      },
    );
  } else {
    // Mainnet
    const githubTokens = tokenList || [];
    // add ABI to token object
    const mainnetTokens = githubTokens.map((token) => {
      const newTokenStructure = { ...token, ABI: ABI };
      return newTokenStructure;
    });
    // @ts-ignore
    assetList.push(...mainnetTokens);
  }

  // console.log(assetList);
  return {
    tokensList: assetList,
    isLoading: false,
  };
};
