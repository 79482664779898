import { Flex, Modal } from '@node-real/uikit';
import styled from '@emotion/styled';
import { BackIcon } from '@node-real/icons';

// import { GA_MAP, reportEvent } from '../../utils/ga';

interface MobileWithdrawProgressProps {
  isOpen: boolean;
  handleOpen: () => void;
}
export const MobileWithdrawProgress = (props: MobileWithdrawProgressProps) => {
  const { isOpen, handleOpen } = props;

  return (
    <Container
      isOpen={isOpen}
      onClose={() => {
        handleOpen();
      }}
      title={'Auto VS Manual'}
      description={`Hello`}
      overlayProps={{
        backdropFilter: 'blur(10px)',
      }}
    >
      <BackIcon
        onClick={() => {
          handleOpen();
        }}
        borderRadius={'4px'}
        left={16}
        top={16}
        zIndex={10}
        position={'absolute'}
        cursor={'pointer'}
        color={'bg.card'}
        _hover={{
          background: 'bg.top.active',
        }}
      />
      <Flex
        position={'absolute'}
        width={'100%'}
        top={16}
        height={40}
        left={0}
        justifyContent={'center'}
        borderBottom={'1px solid'}
        borderColor={'readable.top.secondary'}
        fontFamily={'Space Grotesk'}
        fontSize={16}
        fontWeight={700}
      >
        Auto VS Manual
      </Flex>
      <img
        src="/images/m_withdraw_progress.png"
        alt="withdraw_progress"
        sizes="100vw"
        style={{
          width: '100%',
          height: 'auto',
        }}
        width={654}
        height={1624}
      />
    </Container>
  );
};

const Container = styled(Modal)`
  border-radius: 0;
  box-shadow: ${(props: any) => props.theme.colors.shadows?.normal};
  justify-content: center;
  align-items: center;
  .ui-modal-content {
    padding: 80px 16px 16px;
    border-radius: 0;
    max-width: 100%;
    width: 100%;
    margin:0 ;
    overflow-y: auto;
    background: ${(props: any) => props.theme.colors.readable.normal};
    color: ${(props: any) => props.theme.colors.bg?.card};
  }
  .ui-modal-body {
    font-size: 18px;
    margin-top: 16px;
    color: ${(props: any) => props.theme.colors.readable.pageButton};
  }
  .ui-state-modal-title {
    font-weight: 700;
    font-size: 24px;auto
    margin-top: 16px;
  }
  .ui-close-button {
    color: ${(props: any) => props.theme.colors.bg?.card};
  }
  .ui-state-modal-icon {
    display: none;
  }
  &::after, &::before {
    height: 0px;
  }
`;
