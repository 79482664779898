import { Flex, Modal, ModalCloseButton } from '@node-real/uikit';
import styled from '@emotion/styled';
import { mobileMedia } from '@op-bridge/bridge-core';

import WaitingConfirm from '../svgIcon/WaitingConfirm';

interface WaitingForConfirmationProps {
  isOpen: boolean;
  handleOpen: (show: boolean) => void;
}
export const WaitingForConfirmation = (props: WaitingForConfirmationProps) => {
  const { isOpen, handleOpen } = props;

  return (
    <Container
      isOpen={isOpen}
      onClose={() => handleOpen(false)}
      margin={0}
      overlayProps={{
        backdropFilter: 'blur(10px)',
      }}
    >
      <ModalCloseButton
        right={24}
        top={16}
        width={24}
        height={24}
        onClick={() => handleOpen(false)}
        color={'bg.card'}
      />
      <Content>
        <WaitingConfirm width={80} height={80} mb={[16, 0, 0]} />
        <Title> Waiting for Confirmation</Title>
        <Subtitle> Confirm this transaction in your wallet.</Subtitle>
      </Content>
    </Container>
  );
};

const Container = styled(Modal)`
  border-radius: 12px;
  box-shadow: ${(props: any) => props.theme.colors.shadows?.normal};
  justify-content: center;
  align-items: center;
  .ui-modal-content {
    padding: 48px 24px;
    width: 542px;
    background: ${(props: any) => props.theme.colors.readable.normal};
    color: ${(props: any) => props.theme.colors.bg?.card};
    ${mobileMedia} {
      padding: 24px;
      max-width: 542px !important;
      margin: 0 16px;
      width: 100%;
    }
  }
`;

const Content = styled(Flex)`
  flex-direction: column;
  gap: 16px;
  align-items: center;
  ${mobileMedia} {
    gap: 0;
  }
`;

const Title = styled.div`
  font-family: 'Space Grotesk';
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  white-space: nowrap;
  ${mobileMedia} {
    margin-bottom: 16px;
  }
`;

const Subtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: ${(props: any) => props.theme.colors.readable.pageButton};
  ${mobileMedia} {
    font-size: 18px;
    text-align: center;
  }
`;
