import styled from '@emotion/styled';
import { mobileMedia } from '@op-bridge/bridge-core';

export const Container = styled.div`
  height: 100%;
  padding: 32px 32px 0;

  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 652px;
  width: 100%;
  ${mobileMedia} {
    padding: 24px 12px 0;
    max-width: 100%;
  }
`;

export const WarningText = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: ${(props: any) => props.theme.colors.scene?.orange?.warning};
`;
