import { ethers } from 'ethers';
import { useAccount, useSigner } from 'wagmi';
import { useCallback, useState } from 'react';
import { TokenObjectType, batchUpdate } from '@op-bridge/bridge-core';

import * as env from '../env';
import { useGetTokenList } from './useGetTokenList';
import { useTxFailed } from './useTxFailed';

export const useApproveAutoWithdraw = () => {
  const { address } = useAccount();
  const { data: signer2 } = useSigner({
    chainId: Number(env.L2_CHAIN_ID),
  });
  const [isApproving, setIsApproving] = useState(false);
  const [waitingConfirmation, setWaitingConfirmation] = useState(false);
  const [approveError, setApproveError] = useState('');
  const { tokensList, isLoading } = useGetTokenList();
  const { showError, handleErrorShow } = useTxFailed();

  // Get BEP20 token on L2 auto withdrawal contract
  const approveL2Token = useCallback(
    async (withdrawalAmount: string, tokenType: string) => {
      try {
        if (
          address &&
          signer2 &&
          (env.NET_ENV === 'Testnet' || (env.NET_ENV === 'Mainnet' && !isLoading))
        ) {
          const asset = tokensList.filter((asset: TokenObjectType) => asset.symbol === tokenType);

          if (Array.isArray(asset) && asset?.length) {
            setIsApproving(true);
            // Convert withdrawal amount to wei
            const amount = ethers.utils.parseUnits(withdrawalAmount, 'ether');
            const l2ERC20 = new ethers.Contract(asset[0].l2Address, asset[0].ABI, signer2);
            // Approve auto withdraw contract to spend the token
            const approveResult = await l2ERC20.approve(
              env.AUTO_WITHDRAW_ADDRESS, // auto withdrawal contract address
              amount,
            );
            // Wait till transaction is on chain
            await approveResult.wait();
            // eslint-disable-next-line no-console
            console.log(approveResult);
            setIsApproving(false);
            return approveResult;
          }
        }
      } catch (e: any) {
        // eslint-disable-next-line no-console
        console.log(e);
        batchUpdate(() => {
          setIsApproving(false);
          setApproveError(e.code || e.message);
          setWaitingConfirmation(false);
        });
      }
    },
    [address, isLoading, tokensList, signer2],
  );

  return {
    approveL2Token,
    isApproving,
    approveError,
    showError,
    handleErrorShow,
    waitingConfirmation,
    setWaitingConfirmation,
  };
};
