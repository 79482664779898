import { Flex, Switch, Tooltip, useOutsideClick } from '@node-real/uikit';
import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { useResponsive } from '@op-bridge/bridge-core';

import { GA_MAP, reportEvent } from '../../utils/ga';
import { getDataFromCache } from '../../utils';
import { useAutoWithdraw } from '../../context/autoWithdraw/useAutoWithdrawSwitch';
import { addDataIntoCache } from '../../utils';
import { AutoWithdrawInfoIcon } from '../svgIcon/AutoWithdrawInfoIcon';
import { MobileWithdrawProgress } from '../modal/MobileWithdrawProgress';

const TooltipContent = () => {
  return (
    <Flex flexDirection={'column'} gap={4} gridGap={4}>
      <img
        src="/images/d_withdraw_progress.png"
        alt="withdraw_progress"
        sizes="100vw"
        style={{
          width: '100%',
          height: 'auto',
        }}
        width={988}
        height={892}
      />
    </Flex>
  );
};

export const AutoWithdrawSwitch = ({
  handleAutoSwitch,
}: {
  handleAutoSwitch: (isAutoSwitch: boolean) => void;
}) => {
  const { autoWithdrawDispatch } = useAutoWithdraw();
  const router = useRouter() as any;
  const ref = React.useRef();
  const { isMobile } = useResponsive();
  const [autoWithdrawTab, setAutoWithdrawTab] = useState<any>(null);
  const [openToolTip, setOpenToolTip] = useState(false);
  const [toolTipClass, setToolTipClass] = useState('');
  const [showProgressImg, setShowProgressImg] = useState(false);

  useOutsideClick({
    ref: ref as any,
    handler: () => setOpenToolTip(false),
  });

  const handleModalOpen = useCallback(() => {
    setShowProgressImg((pre) => !pre);
  }, []);

  const getAutoWithdrawCache = useCallback(async () => {
    const cache = await getDataFromCache(window.location.hostname, {
      cacheName: 'autoWithdraw',
      deleteCacheWhenTimeOut: false,
    });
    return cache;
  }, []);

  const onOpen = useCallback(() => {
    reportEvent({
      name: GA_MAP.autoWithdrawSwitchInfo,
      data: {
        name: 'Auto Withdrawal Switch Info',
      },
    });
    if (isMobile) {
      handleModalOpen();
    } else {
      setOpenToolTip((pre) => {
        return !pre;
      });
    }
  }, [isMobile, handleModalOpen]);

  const onChange = useCallback(async (event: any) => {
    reportEvent({
      name: GA_MAP.autoWithdrawSwitch,
      data: {
        name: 'Auto Withdrawal Switch',
      },
    });
    if (event.target.checked === false) {
      autoWithdrawDispatch({ type: 'DISABLE' });
      handleAutoSwitch(false);
      setAutoWithdrawTab({ enable: false });
      await addDataIntoCache(window.location.hostname, { enable: false }, 'autoWithdraw');
    } else {
      autoWithdrawDispatch({ type: 'ENABLE' });
      handleAutoSwitch(true);
      setAutoWithdrawTab({ enable: true });
      await addDataIntoCache(window.location.hostname, { enable: true }, 'autoWithdraw');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Retrieve data from cache
  useEffect(() => {
    getAutoWithdrawCache().then((res) => {
      if (res) {
        setAutoWithdrawTab(res);
      } else {
        // init tab
        setAutoWithdrawTab({ enable: true });
      }
    });
  }, [getAutoWithdrawCache, router?.asPath]);

  return (
    <Flex
      justifyContent={'center'}
      alignItems={'center'}
      mt={-9}
      mb={24}
      color={'bg.card'}
      fontSize={14}
      fontWeight={500}
      fontFamily={'Space Grotesk'}
    >
      <Tooltip
        padding={'24px 12px 36px'}
        color={'bg.card'}
        background={'readable.normal'}
        border={'none'}
        content={<TooltipContent />}
        width={518}
        maxWidth={518}
        placement="bottom"
        right={-17}
        isOpen={isMobile ? false : openToolTip}
        boxShadow={'0px 4px 24px 0px rgba(0, 0, 0, 0.08)'}
      >
        <SwitchText
          ref={ref as any}
          className={toolTipClass}
          flexDirection={'row'}
          justifyContent={'center'}
          alignItems={'center'}
          onClick={onOpen}
          onMouseEnter={() => setToolTipClass('hover')}
          onMouseLeave={() => setToolTipClass('')}
          cursor={'pointer'}
          _hover={{ color: 'scene.primary.normal' }}
        >
          Auto withdrawal
          <AutoWithdrawInfoIcon width={16} height={16} ml={4} />
        </SwitchText>
      </Tooltip>
      <StyledSwitch
        w={26}
        h={16}
        ml={8}
        isChecked={autoWithdrawTab?.enable || false}
        onChange={onChange}
      />
      <MobileWithdrawProgress isOpen={showProgressImg} handleOpen={handleModalOpen} />
    </Flex>
  );
};

const SwitchText = styled(Flex)`
  svg path {
    color: ${(props: any) => props.theme.colors.readable?.secondary};
  }
  &.hover {
    svg path {
      color: ${(props: any) => props.theme.colors.scene.primary.normal};
    }
  }
`;

const StyledSwitch = styled(Switch)`
  .ui-switch-thumb {
    width: 13px;
    height: 14px;
  }

  .ui-switch-thumb[data-checked] {
    left: calc(100% - 15px);
  }
  .ui-switch-track[data-checked] {
    background: ${(props: any) => props.theme.colors.scene.success.progressBar};
  }
`;
