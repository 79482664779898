import { useCallback, useState } from 'react';
import { useResponsive } from '@op-bridge/bridge-core';

import { useModal } from './useModal';

export const useTxSuccessful = () => {
  const [showSuccessful, setShowSuccessful] = useState(true);
  const modalData = useModal();
  const { isMobile } = useResponsive();

  const handleSuccessfulShow = useCallback(
    (show: boolean) => {
      if (show) {
        // eslint-disable-next-line no-console
        // console.log('modal open');
        setShowSuccessful(show);
        if (!isMobile) {
          // document.body.style.overflow = 'hidden';
        }
        modalData.modalDispatch({ type: 'OPEN' });
      } else {
        // eslint-disable-next-line no-console
        // console.log('modal close');
        setShowSuccessful((preState) => !preState);
        document.body.style.overflow = '';
        modalData.modalDispatch({ type: 'CLOSE' });
      }
    },
    [modalData, isMobile],
  );

  return { showSuccessful, handleSuccessfulShow };
};
