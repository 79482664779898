import { Icon, IconProps } from '@node-real/icons';

export const BNB = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="10" cy="10" r="10" fill="#F0B90B" />
      <path
        d="M6.27058 9.99995L4.74058 11.5406L3.19995 9.99995L4.74058 8.45933L6.27058 9.99995ZM9.99995 6.27058L12.635 8.90558L14.1756 7.36495L11.5406 4.74058L9.99995 3.19995L8.45933 4.74058L5.83495 7.36495L7.37558 8.90558L9.99995 6.27058ZM15.2593 8.45933L13.7293 9.99995L15.27 11.5406L16.8 9.99995L15.2593 8.45933ZM9.99995 13.7293L7.36495 11.0943L5.83495 12.635L8.46995 15.27L9.99995 16.8L11.5406 15.2593L14.1756 12.6243L12.635 11.0943L9.99995 13.7293ZM9.99995 11.5406L11.5406 9.99995L9.99995 8.45933L8.45933 9.99995L9.99995 11.5406Z"
        fill="white"
      />
    </Icon>
  );
};
