export * from './useChainBalance';
export * from './useDeposit';
export * from './useERC20Deposit';
export * from './useERC20Withdraw';
export * from './useGetAllowance';
export * from './useGetChainProviders';
export * from './useL2Messenger';
export * from './useGetGasPrice';
export * from './useGetSigners';
export * from './useInputValidator';
export * from './usePausedStatus';
export * from './usePending';
export * from './useL1Withdraw';
export * from './useSaveFuncRef';
export * from './useSwitchAccount';
export * from './useTxSuccessful';
export * from './useWeb3';
export * from './useWithdraw';
export * from './useModal';
export * from './useTxFailed';
export * from './useWithdrawConfirm';
export * from './useGetL1DataFee';
export * from './useSDKGetL1DataGas';
export * from './useAutoWithdraw';
export * from './useApproveAutoWithdraw';
export * from './useGetAutoWithdrawAllowance';

export const defaultSetting = {
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  retry: 1,
};
