import { useCallback, useEffect, useState } from 'react';
import { useAccount, useNetwork } from 'wagmi';
import * as optimismSDK from '@eth-optimism/sdk';
import { BigNumber, ethers } from 'ethers';

import { contracts } from '../base/sdkContract';
import * as env from '../env';
import { getEtherFormatValue } from '../utils';

const l1ChainId = Number(env.L1_CHAIN_ID);
const l2ChainId = Number(env.L2_CHAIN_ID);
const selfL1StandardBridge = env.L1StandardBridge;
const predeploys = require('@eth-optimism/contracts');

export const useGetL2EstimateGas = () => {
  const { address } = useAccount();
  const { chain: currentChain } = useNetwork();
  const [crossChainMessenger, setCrossChainMessenger] =
    useState<optimismSDK.CrossChainMessenger | null>(null);
  const [loadingGasLimit, setLoadingGasLimit] = useState(false);

  useEffect(() => {
    if (address) {
      const l2RpcProvider = new ethers.providers.JsonRpcProvider(env.L2_RPC_URL, 'any');
      const l1RpcProvider = new ethers.providers.JsonRpcProvider(env.L1_RPC_URL, 'any');
      const l2Signer = l2RpcProvider.getSigner(address);
      const l1Signer = l1RpcProvider.getSigner(address);
      if (l2Signer && l1Signer) {
        try {
          const messenger = new optimismSDK.CrossChainMessenger({
            l1ChainId: l1ChainId,
            l2ChainId: l2ChainId,
            l1SignerOrProvider: l1Signer,
            l2SignerOrProvider: l2Signer,
            bedrock: true,
            contracts: contracts,
          });
          const bridgess = optimismSDK.getBridgeAdapters(l2ChainId, messenger, {
            overrides: {
              Standard: {
                Adapter: optimismSDK.StandardBridgeAdapter,
                l1Bridge: selfL1StandardBridge,
                l2Bridge: predeploys.predeploys.L2StandardBridge,
              },
              ETH: {
                Adapter: optimismSDK.ETHBridgeAdapter,
                l1Bridge: selfL1StandardBridge,
                l2Bridge: predeploys.predeploys.L2StandardBridge,
              },
            },
          });

          messenger.bridges = bridgess;
          setCrossChainMessenger(messenger);
        } catch (e) {
          //eslint-disable-next-line no-console
          console.log(e);
        }
      }
    }
  }, [address]);

  const getL2EstimateGas = useCallback(
    async (tokeNumber: string, type: string) => {
      if (crossChainMessenger && type === 'withdraw') {
        setLoadingGasLimit(true);
        let estiGas;
        try {
          if (currentChain && currentChain.id === Number(env.L2_CHAIN_ID)) {
            estiGas = await crossChainMessenger.estimateGas.withdrawETH(
              ethers.utils.parseUnits(tokeNumber, 'ether'),
              {
                overrides: { from: address },
              },
            );
          }
          return estiGas
            ? { val: await getEtherFormatValue(estiGas), error: false, msg: '' }
            : {
                val: await getEtherFormatValue(BigNumber.from('0')),
                error: true,
                msg: 'Unable to get estimated gas from op SDK',
              };
        } catch (e: any) {
          //eslint-disable-next-line no-console
          console.log(e);
          const bn0 = BigNumber.from('0');
          return { val: await getEtherFormatValue(bn0), error: true, msg: e?.code };
        } finally {
          setLoadingGasLimit(false);
        }
      }
    },
    [address, crossChainMessenger, currentChain],
  );

  return { getL2EstimateGas, loadingGasLimit };
};
