import { BN } from 'bn.js';
import styled from '@emotion/styled';
import { Button } from '@node-real/uikit';

import { addDataIntoCache, batchUpdate, divide10Exp } from '../../utils';
import { GA_MAP, reportEvent } from '../../utils/ga';

interface WithdrawActionButtonProps {
  data: any;
  disabled: boolean;
  refreshHistoryTable?: () => void;
  setButtonSubmit: (status: number | null) => void;
  setInitHash: (tx: string) => void;
  setProveHash: (tx: string) => void;
  setFinalizeHash: (tx: string) => void;
  setWithdrawVal: (amount: number) => void;
  setCurrentToken: (token: string) => void;
  setIsConfirming: (isConfirm: boolean) => void;
  handleWithdrawToggle: () => void;
  proveWithdraw: (hash: string) => Promise<string | null>;
  finalWithdraw: (hash: string) => Promise<string | null>;
  place?: string;
}

export const WithdrawActionButton = (props: WithdrawActionButtonProps) => {
  const {
    data,
    disabled,
    refreshHistoryTable,
    setButtonSubmit,
    setInitHash,
    setProveHash,
    setFinalizeHash,
    setWithdrawVal,
    setCurrentToken,
    setIsConfirming,
    handleWithdrawToggle,
    proveWithdraw,
    finalWithdraw,
    place,
  } = props;

  return (
    <>
      <ActionButton
        withdrawtype={
          ((data.receiptsStatus === 2 && !disabled) || (data.receiptsStatus === 4 && !disabled)) &&
          data?.isAuto !== true
            ? 'active'
            : 'detail'
        }
        onClick={() => {
          // eslint-disable-next-line no-console
          console.log(data);
          // prove withdraw
          if (data.receiptsStatus === 2 && !disabled && !data?.isAuto === true) {
            setIsConfirming(true);
            if (place && place === 'ongoing') {
              reportEvent({
                name: GA_MAP.ongoingProveClick,
                data: { name: 'Ongoing Prove Withdraw' },
              });
            } else {
              reportEvent({ name: GA_MAP.prove, data: { name: 'Prove Withdraw' } });
            }
            proveWithdraw(data?.hash)
              .then(async (res) => {
                if (res) {
                  batchUpdate(() => {
                    setButtonSubmit(1);
                    setProveHash(res);
                  });
                  if (typeof window !== 'undefined') {
                    // Add temp status into cache
                    await addDataIntoCache(window.location.hostname, {
                      [`${data?.hash}`]: data.receiptsStatus,
                    });
                  }
                  refreshHistoryTable && refreshHistoryTable();
                }
                setIsConfirming(false);
              })
              .catch((e: any) => {
                // eslint-disable-next-line no-console
                console.log(e);
                setIsConfirming(false);
              });
          } else if (data.receiptsStatus === 4 && !disabled && !data?.isAuto === true) {
            setIsConfirming(true);
            if (place && place === 'ongoing') {
              reportEvent({
                name: GA_MAP.ongoingFinalizeClick,
                data: { name: 'Ongoing Finalize Withdraw' },
              });
            } else {
              reportEvent({ name: GA_MAP.finalize, data: { name: 'Finalize Withdraw' } });
            }
            finalWithdraw(data?.hash)
              .then(async (res) => {
                if (res) {
                  batchUpdate(() => {
                    setButtonSubmit(2);
                    setFinalizeHash(res);
                  });
                  if (typeof window !== 'undefined') {
                    // Add temp status into cache
                    await addDataIntoCache(window.location.hostname, {
                      [`${data?.hash}`]: data.receiptsStatus,
                    });
                  }
                  refreshHistoryTable && refreshHistoryTable();
                }
                setIsConfirming(false);
              })
              .catch((e: any) => {
                // eslint-disable-next-line no-console
                console.log(e);
                setIsConfirming(false);
              });
          } else {
            // Withdrawal details button click
            if (place && place === 'ongoing') {
              reportEvent({
                name: GA_MAP.ongoingDetailsClick,
                data: { name: 'Ongoing Withdraw Details' },
              });
            } else {
              reportEvent({
                name: GA_MAP.withdrawDetailsButton,
                data: { name: 'Withdraw Details' },
              });
            }
          }

          let amount = 0;
          if (data?.asset === 'BNB') {
            if (data?.isAuto) {
              if (data.amount.includes('0x')) {
                const valBn = new BN(data.amount.slice(2), 16);
                amount = Number(divide10Exp(valBn, 18));
              } else {
                amount = Number(data.amount) * Math.pow(10, -18);
              }
            } else if (data.value.includes('0x')) {
              const valBn = new BN(data.value.slice(2), 16);
              amount = Number(divide10Exp(valBn, 18));
            } else {
              amount = Number(data.value) * Math.pow(10, -18);
            }
          } else {
            /**
             * Bep20 Token Amount
             */
            if (data?.amount !== null || data?.amount !== undefined) {
              const BNvalue = new BN(data?.amount.replace('0x', ''), 16);
              amount = Number(divide10Exp(BNvalue, 18));
            }
          }

          // Prove withdraw first and then show popup window to avoid wrong button text
          batchUpdate(() => {
            if (data?.asset) setCurrentToken(data?.asset);
            setWithdrawVal(amount);
            setInitHash(data?.hash);
            if (data?.l1ProveTxHash) {
              setProveHash(data?.l1ProveTxHash);
            } else {
              setProveHash('');
            }
            if (data?.l1TxHash && Number(data?.l1TxHash) !== 0) {
              setFinalizeHash(data?.l1TxHash);
            } else {
              setFinalizeHash('');
            }
          });
          handleWithdrawToggle();
        }}
      >
        {data.receiptsStatus === 2 && !disabled && !data?.isAuto === true
          ? 'Prove'
          : data.receiptsStatus === 4 && !disabled && !data?.isAuto === true
          ? 'Finalize'
          : 'Details'}
      </ActionButton>
    </>
  );
};

const ActionButton = styled(Button)<{ withdrawtype: string }>`
  border-radius: 4px;
  height: 32px;
  width: 68px;
  padding: 3px 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  white-space: nowrap;
  border: ${(props: any) => (props.withdrawtype === 'active' ? 'none' : '1px solid #fff')};
  color: ${(props: any) =>
    props.withdrawtype === 'active' ? props.theme.colors.bg?.card : '#fff'};
  background: ${(props: any) =>
    props.withdrawtype === 'active' ? props.theme.colors.scene?.primary.normal : 'none'};
  &:hover {
    background: ${(props: any) =>
      props.withdrawtype === 'active' ? props.theme.colors.scene?.primary.active : '#fff'};
    color: ${(props: any) => props.theme.colors.bg?.card};
  }
  &[disabled] {
    background: ${(props: any) => props.theme.colors.readable?.disabled};
    color: ${(props: any) => props.theme.colors.readable?.secondary};
    opacity: 1;
    &:hover {
      background: ${(props: any) => props.theme.colors.readable?.disabled};
      color: ${(props: any) => props.theme.colors.readable?.secondary};
      opacity: 1;
    }
  }
`;
