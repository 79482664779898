import styled from '@emotion/styled';
import { Button } from '@node-real/uikit';
import { useState } from 'react';
import { TokenObjectType } from '@op-bridge/bridge-core';

import { reportEvent, GA_MAP } from '../../utils/ga';
import { useERC20Deposit, useGetAllowance, usePending, useTxFailed } from '../../hooks';
import { ButtonWarning } from '../ButtonWarning';
import { WaitingForConfirmation } from '../modal/WaitingForConfirmation';
import { batchUpdate } from '../../utils';
import { TxError } from '../modal/TxError';
export const ApproveButton = ({
  amount,
  asset,
  allowance,
  tokenType,
  handleAllowanceUpdate,
  disabled,
  updateEstimateGas,
}: {
  amount: string;
  asset: TokenObjectType[];
  allowance: number;
  tokenType: string;
  handleAllowanceUpdate: (amount: number) => void;
  disabled: boolean;
  updateEstimateGas: () => void;
}) => {
  const {
    approveERC20,
    txFailedMsg: hooksErrorMsg,
    showError: hooksShowError,
    handleErrorShow: hookHandleErrorShow,
  } = useERC20Deposit();
  const { getL1Allowance } = useGetAllowance();
  const { showPending, handlePendingShow } = usePending();
  const [waitingConfirmation, setWaitingConfirmation] = useState(false);
  const { showError, handleErrorShow } = useTxFailed();
  const [txFailedMsg, setTxFailedMsg] = useState('');

  return (
    <>
      <StyledButton
        disabled={disabled}
        color={'bg.card'}
        onClick={() => {
          if (allowance > 0) {
            reportEvent({ name: GA_MAP.resetApprove, data: { name: 'Click ERC20 Reset Approve' } });
          } else {
            reportEvent({ name: GA_MAP.approve, data: { name: 'Click ERC20 Approve' } });
          }
          try {
            setWaitingConfirmation(true);
            handlePendingShow(true);
            approveERC20(amount, asset[0])
              .then(() => {
                // Get remaining allowance value
                getL1Allowance(tokenType)
                  .then((res: string) => {
                    if (res) {
                      // console.log('Approve ERC20 update allowance amount to', res);
                      handleAllowanceUpdate(Number(res) * Math.pow(10, -18));
                    }
                    setWaitingConfirmation(false);
                    handlePendingShow(false);
                  })
                  .then(() => {
                    // Update estimate gas after getting latest allowance
                    updateEstimateGas();
                  });
              })
              .catch((e: any) => {
                // eslint-disable-next-line no-console
                console.log(e?.code);
                if (e && e?.code) {
                  // do not show error if user accelerate tx (tx hash replaced)
                  if (e?.code === 'TRANSACTION_REPLACED' && e?.replacement?.hash) {
                    // eslint-disable-next-line no-console
                    console.log(e.replacement);
                  } else {
                    setTxFailedMsg(e?.code);
                  }
                } else {
                  setTxFailedMsg(e);
                }
                handleErrorShow(true);
                batchUpdate(() => {
                  setWaitingConfirmation(false);
                  handlePendingShow(false);
                });
              });
          } catch (e: any) {
            if (e && e?.code) {
              // do not show error if user accelerate tx (tx hash replaced)
              if (e?.code === 'TRANSACTION_REPLACED' && e?.replacement?.hash) {
                // eslint-disable-next-line no-console
                console.log(e.replacement);
              } else {
                setTxFailedMsg(e?.code);
              }
            } else {
              setTxFailedMsg(e);
            }
            handleErrorShow(true);
            batchUpdate(() => {
              setWaitingConfirmation(false);
              handlePendingShow(false);
            });
            // eslint-disable-next-line no-console
            console.log(e);
          }
        }}
      >
        Approve
      </StyledButton>
      <ButtonWarning
        text={
          allowance > 0
            ? `Exceeds the spending cap set for ${tokenType}. Approve resetting it to proceed.`
            : `Please approve setting a spending cap for ${asset[0].name} first.
            `
        }
      ></ButtonWarning>
      {waitingConfirmation ? (
        <WaitingForConfirmation
          isOpen={showPending}
          handleOpen={handlePendingShow}
        ></WaitingForConfirmation>
      ) : null}

      {hooksErrorMsg && (
        <TxError
          isOpen={hooksShowError}
          title="Transaction Failed"
          handleOpen={() => {
            hookHandleErrorShow(false);
          }}
          errorCode={hooksErrorMsg}
        />
      )}

      {txFailedMsg && (
        <TxError
          isOpen={showError}
          title="Transaction Failed"
          handleOpen={() => {
            handleErrorShow(false);
          }}
          errorCode={txFailedMsg}
        />
      )}
    </>
  );
};

const StyledButton = styled(Button)`
  line-height: 20px;
  height: 48px;
  border-radius: 8px;
  background: ${(props: any) => props.theme.colors.scene.orange.normal};
  color: white;
  &:hover {
    background: ${(props: any) => props.theme.colors.scene.orange.active};
  }
  &[disabled] {
    &,
    &:hover {
      color: ${(props: any) => props.theme.colors.readable?.secondary};
      background: ${(props: any) => props.theme.colors.readable?.disabled};
      opacity: 1;
    }
  }
`;
