import { Button } from '@node-real/uikit';
import { useTheme } from '@emotion/react';

import { useAutoWithdraw, useChainBalance, useGetGasPrice, useGetL1DataFee } from '../../hooks';
import {
  L1_RPC_URL,
  L2_RPC_URL,
  NET_ENV,
  DEPOSIT_COEFFICIENT,
  WITHDRAW_COEFFICIENT,
} from '../../env';
import { Loading } from '../Loading';
import { reportEvent, GA_MAP } from '../../utils/ga';
import { useGetL2EstimateGas } from '../../hooks/useGetL2EstimateGas';

type MaxButtonProps = {
  type: string;
  tokenName: string;
  setInputVal: (val: string) => void;
  loadGasPrice: (val: string) => void;
  setIsMaxLoading: (val: boolean) => void;
  isMaxLoading: boolean;
};

export const MaxButton = (props: MaxButtonProps) => {
  const { type, tokenName, setInputVal, loadGasPrice, setIsMaxLoading, isMaxLoading } = props;
  const theme = useTheme() as any;

  const { l1balance, l2balance, l1BalanceVal, l2BalanceVal, isLoading } =
    useChainBalance(tokenName);
  const { getEstimateGas, getGasPrice } = useGetGasPrice();
  const { getL2EstimateGas } = useGetL2EstimateGas();

  const { loadL1DataFee } = useGetL1DataFee();
  const { getDelegationFee } = useAutoWithdraw();

  return (
    <Button
      height={'18px'}
      size={'sm'}
      gap={'2px'}
      color={theme.colors.readable.link}
      border={`1px solid ${theme.colors.readable.border}`}
      fontSize={'10px'}
      fontWeight={600}
      borderRadius={'360px'}
      isDisabled={isLoading || isMaxLoading}
      px={'6px'}
      _hover={{
        border: `1px solid ${theme.colors.scene.primary.normal}`,
        color: theme.colors.scene.primary.normal,
      }}
      sx={{
        ':disabled': {
          opacity: 1,
          color: theme.colors.readable.disabled,
          borderColor: theme.colors.readable.disabled,
          ':hover': {
            color: theme.colors.readable.disabled,
            borderColor: theme.colors.readable.disabled,
          },
        },
      }}
      variant={'link'}
      onClick={(e) => {
        e.stopPropagation();
        setTimeout(async () => {
          try {
            if (type === 'deposit') {
              reportEvent({
                name: GA_MAP.depositMaxButton,
                data: {
                  name: 'Click max deposit',
                },
              });
              if (
                tokenName === 'BNB' &&
                l1balance?.formatted &&
                Number(l1balance?.formatted) >= Math.pow(10, -8)
              ) {
                setIsMaxLoading(true);
                loadGasPrice(l1balance?.formatted);
                const gasLimit = await getEstimateGas(l1balance?.formatted, 'deposit');
                const gasPrice = await getGasPrice(L1_RPC_URL);
                const gasFee = Number(gasLimit?.val) * Math.pow(10, 18) * Number(gasPrice);
                const depositVal =
                  Number(l1balance?.formatted) - gasFee * 1.5 * DEPOSIT_COEFFICIENT[NET_ENV];
                // eslint-disable-next-line no-console
                console.log('deposit gasLimit', gasLimit?.val, 'gasPrice', gasPrice);
                if (depositVal < 0) {
                  setInputVal('0');
                } else {
                  setInputVal(depositVal.toFixed(8));
                }
                setIsMaxLoading(false);
              } else if (l1BalanceVal > 0) {
                const valWithDecimals = l1BalanceVal
                  ?.toLocaleString('fullwide', { maximumFractionDigits: 8, useGrouping: false })
                  ?.match(/^\d+(?:\.\d{0,8})?/)?.[0] as string;
                if (Number(valWithDecimals) > l1BalanceVal) {
                  let reducedAmt = (Number(valWithDecimals) - Math.pow(10, -8)).toLocaleString(
                    'fullwide',
                    {
                      maximumFractionDigits: 8,
                      useGrouping: false,
                    },
                  );
                  reducedAmt = reducedAmt.replace('。', '.').replace(',', '.');
                  loadGasPrice(reducedAmt);
                  setInputVal(reducedAmt);
                } else {
                  loadGasPrice(valWithDecimals);
                  setInputVal(valWithDecimals);
                }
              } else {
                setInputVal('0');
              }
            } else {
              // withdraw
              reportEvent({
                name: GA_MAP.withdrawMaxButton,
                data: {
                  name: 'Click max withdraw',
                },
              });

              if (
                tokenName === 'BNB' &&
                l2balance?.formatted &&
                Number(l2balance?.formatted) >= Math.pow(10, -8)
              ) {
                setIsMaxLoading(true);
                loadGasPrice(l2balance?.formatted);
                const gasLimit = await getL2EstimateGas(l2balance?.formatted, 'withdraw');
                const gasPrice = await getGasPrice(L2_RPC_URL);
                const l1DataFee = await loadL1DataFee();
                const delegationFee = await getDelegationFee();
                const gasFee = Number(gasLimit?.val) * Math.pow(10, 18) * Number(gasPrice);
                const withdrawVal =
                  Number(l2balance?.formatted) -
                  (gasFee + l1DataFee * Math.pow(10, -18) + delegationFee) *
                    WITHDRAW_COEFFICIENT[NET_ENV];
                // eslint-disable-next-line no-console
                console.log('withdraw gasLimit', gasLimit?.val, 'gasPrice', gasPrice);
                if (withdrawVal < 0) {
                  setInputVal('0');
                } else {
                  setInputVal(withdrawVal.toFixed(8));
                }
                setIsMaxLoading(false);
              } else if (l2BalanceVal > 0) {
                const valWithDecimals = l2BalanceVal
                  ?.toLocaleString('fullwide', { maximumFractionDigits: 8, useGrouping: false })
                  ?.match(/^\d+(?:\.\d{0,8})?/)?.[0] as string;
                if (Number(valWithDecimals) > l2BalanceVal) {
                  let reducedAmt = (Number(valWithDecimals) - Math.pow(10, -8)).toLocaleString(
                    'fullwide',
                    {
                      maximumFractionDigits: 8,
                      useGrouping: false,
                    },
                  );
                  reducedAmt.replace('。', '.').replace(',', '.');
                  loadGasPrice(reducedAmt);
                  setInputVal(reducedAmt);
                } else {
                  loadGasPrice(valWithDecimals);
                  setInputVal(valWithDecimals);
                }
              } else {
                setInputVal('0');
              }
            }
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
          }
        }, 500);
      }}
    >
      MAX {isMaxLoading && <Loading minHeight={10} svgStyle={{ height: '10px', width: '10px' }} />}
    </Button>
  );
};
