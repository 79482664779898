import React from 'react';

export const initialState = {
  enable: true,
};

export interface AutoWithdrawState {
  autoWithdrawState: {
    enable: boolean;
  };
  autoWithdrawDispatch: React.Dispatch<any>;
}

export const AutoWithdrawContext = React.createContext<AutoWithdrawState>(null as any);
AutoWithdrawContext.displayName = 'AutoWithdrawContext';

export const AutoWithdrawReducer = (initialState: any, action: any) => {
  switch (action.type) {
    case 'ENABLE':
      return {
        enable: true,
      };
    case 'DISABLE':
      return {
        enable: false,
      };
    default:
      return initialState;
  }
};

export * from './Provider';
