import { TokenObjectType } from '@op-bridge/bridge-core';
import { ethers } from 'ethers';
import { useAccount, useSigner } from 'wagmi';
import { useCallback, useEffect, useState } from 'react';

import * as env from '../env';
import ABI from '../base/L2StandardBridgeBot.json';

const l2RpcProvider = new ethers.providers.JsonRpcProvider(env.L2_RPC_URL, 'any');

export const useAutoWithdraw = () => {
  const [autoWithdrawContract, setAutoWithdrawContract] = useState<any>(null);
  const [autoWithdrawContractJson, setAutoWithdrawContractJson] = useState<any>(null);
  const { address } = useAccount();
  const signer2Json = l2RpcProvider.getSigner(address);

  const { data: signer2 } = useSigner({
    chainId: Number(env.L2_CHAIN_ID),
  });

  const [txFailedMsg, setTxFailedMsg] = useState('');

  useEffect(() => {
    if (signer2) {
      const L2StandardBridgeBotContract = new ethers.Contract(
        env.AUTO_WITHDRAW_ADDRESS,
        ABI as any,
        signer2,
      );
      setAutoWithdrawContract(L2StandardBridgeBotContract);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (signer2Json && address) {
      const L2StandardBridgeBotContractJson = new ethers.Contract(
        env.AUTO_WITHDRAW_ADDRESS,
        ABI as any,
        signer2Json,
      );
      setAutoWithdrawContractJson(L2StandardBridgeBotContractJson);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  const BNBAddress = '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000';
  const minGasLimit = 150469;

  // get delegation fee value
  const getDelegationFee = useCallback(async () => {
    const delegationFee = await autoWithdrawContractJson?.delegationFee();

    return delegationFee * Math.pow(10, -18);
  }, [autoWithdrawContractJson]);

  // function to automatically withdraw
  const autoWithdraw = useCallback(
    async (amount: number, asset: TokenObjectType[]) => {
      const address = asset[0]?.symbol === 'BNB' ? BNBAddress : asset[0]?.l2Address;
      const dele = await getDelegationFee();

      const totalAmount = Number(amount).toLocaleString('fullwide', {
        maximumFractionDigits: 18,
        useGrouping: false,
      });

      try {
        const parsedAmt = ethers.utils.parseUnits(totalAmount, 'ether');
        const amount_bn = ethers.utils.parseUnits(
          amount.toLocaleString('fullwide', {
            maximumFractionDigits: 18,
            useGrouping: false,
          }),
          18,
        );
        const dele_bn = ethers.utils.parseUnits(
          dele.toLocaleString('fullwide', {
            maximumFractionDigits: 18,
            useGrouping: false,
          }),
          18,
        );
        const bnb_amount = amount_bn.add(dele_bn);

        const res = await autoWithdrawContract.withdraw(address, parsedAmt, minGasLimit, '0x', {
          value:
            asset[0]?.symbol === 'BNB'
              ? bnb_amount
              : ethers.utils.parseUnits(
                  Number(dele).toLocaleString('fullwide', {
                    maximumFractionDigits: 18,
                    useGrouping: false,
                  }),
                ),
        });

        return res;
      } catch (e: any) {
        setTxFailedMsg(e?.data?.message || e?.message || e?.code);
        //eslint-disable-next-line no-console
        console.log(e);
      }
    },
    [autoWithdrawContract, getDelegationFee],
  );

  return {
    autoWithdraw,
    txFailedMsg,
    getDelegationFee,
  };
};
