import { useCallback, useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { useAccount } from 'wagmi';
import { TokenObjectType, useCheckErc20TokenMatch } from '@op-bridge/bridge-core';

import * as env from '../env';
import { useL1Withdraw } from './useL1Withdraw';
import { usePending } from './usePending';
import { useTxFailed } from './useTxFailed';
import { batchUpdate, getEtherFormatValue } from '../utils';
import { useEthersSigner } from './useGetSigners';
import { L2_RPC_URL } from '../env';

export const useERC20Deposit = () => {
  const { crossChainMessenger } = useL1Withdraw();
  const { address: wagmiAddress } = useAccount();

  const signer1 = useEthersSigner({ chainId: Number(env.L1_CHAIN_ID) });
  const { showPending, handlePendingShow, handlePendingToggle } = usePending();
  const { showError, handleErrorShow, handleErrorToggle } = useTxFailed();
  const { checkTokenAddress } = useCheckErc20TokenMatch(L2_RPC_URL);
  const [isSufficient, setIsSufficient] = useState(false);
  const [address, setAddress] = useState('');
  const [txFailedMsg, setTxFailedMsg] = useState('');
  const [waitingConfirmation, setWaitingConfirmation] = useState(false);

  const reportBalances = useCallback(
    async (asset: TokenObjectType) => {
      if (address) {
        //eslint-disable-next-line
        // const [l1Signer, l2Signer] = getSigners(address);

        try {
          if (signer1 && asset?.l1Address && asset?.ABI) {
            const l1ERC20 = new ethers.Contract(asset.l1Address, asset.ABI, signer1);
            const l1BalanceObj = await l1ERC20.balanceOf(address);
            const balance = await getEtherFormatValue(l1BalanceObj);
            if (balance) {
              return balance;
            } else {
              return null;
            }
          } else {
            return null;
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
          return null;
        }
      }
    },
    [address, signer1],
  );

  useEffect(() => {
    let mount = true;
    if (wagmiAddress && mount) {
      setAddress(wagmiAddress);
    }
    return () => {
      mount = false;
    };
  }, [wagmiAddress]);

  const depositERC20 = async (depositAmount: string, asset: TokenObjectType, gasLimit: number) => {
    if (!asset) return;
    const balance = await reportBalances(asset);
    if (!balance) {
      setIsSufficient(false);
      return null;
    }

    if (crossChainMessenger && depositAmount) {
      // main deposit function
      try {
        handlePendingShow(true);
        setWaitingConfirmation(true);

        // Check token address match
        const pass = await checkTokenAddress(asset);
        if (pass) {
          const response = await crossChainMessenger.depositERC20(
            asset.l1Address,
            asset.l2Address,
            ethers.utils.parseUnits(depositAmount, 'ether'),
            {
              overrides: {
                gasLimit: gasLimit,
              },
              l2GasLimit: 1,
            },
          );
          // await to approve
          await response.wait();

          handlePendingShow(false);
          setWaitingConfirmation(false);

          return response;
        } else {
          throw Error('Your selected token is under maintenance, please try again later.');
        }
      } catch (e: any) {
        setWaitingConfirmation(false);
        handlePendingShow(false);

        if (e && e?.code) {
          batchUpdate(() => {
            try {
              // do not show error if user accelerate tx (tx hash replaced)
              if (e?.code === 'TRANSACTION_REPLACED' && e?.replacement?.hash) {
                // eslint-disable-next-line no-console
                console.log(e.replacement);
                return { hash: e.replacement.hash };
              } else {
                const errorMsg = e?.code;
                setTxFailedMsg(errorMsg);
              }
              // eslint-disable-next-line no-console
              console.log(e?.code);
            } catch (e) {}
            handleErrorShow(true);
          });
        } else {
          setTxFailedMsg(e || 'Transaction Failed');
        }
        // eslint-disable-next-line no-console
        console.log('deposit error', e);
        return null;
      }
    }
    return null;
  };

  const approveERC20 = async (depositAmount: string, asset: TokenObjectType) => {
    try {
      const pass = await checkTokenAddress(asset);
      if (pass) {
        if (crossChainMessenger && address) {
          // Need the l2 address to know which bridge is responsible
          // console.log(asset.L1, asset.L2, depositAmount);
          const allowanceResponse = await crossChainMessenger.approveERC20(
            asset.l1Address,
            asset.l2Address,
            ethers.utils.parseUnits(depositAmount, 'ether'),
          );
          await allowanceResponse.wait();
        }
      } else {
        throw Error('Your selected token is under maintenance, please try again later.');
      }
    } catch (e: any) {
      if (e && e?.code) {
        batchUpdate(() => {
          try {
            const errorMsg = e?.code;
            setTxFailedMsg(errorMsg);
            // eslint-disable-next-line no-console
            console.log(e?.code);
          } catch (e) {}
          handleErrorShow(true);
        });
      } else {
        setTxFailedMsg(e || 'Approve Failed');
        handleErrorShow(true);
      }
      // eslint-disable-next-line no-console
      console.log('approve error', e);
    }
  };

  return {
    depositERC20,
    approveERC20,
    reportBalances,
    isSufficient,
    showPending,
    handlePendingShow,
    handlePendingToggle,
    showError,
    handleErrorShow,
    handleErrorToggle,
    waitingConfirmation,
    txFailedMsg,
  };
};
