import { Icon, IconProps } from '@node-real/icons';

export const AutoWithdrawInfoIcon = (props: IconProps) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1663 7.99968C15.1663 11.6816 12.1816 14.6663 8.49968 14.6663C4.81778 14.6663 1.83301 11.6816 1.83301 7.99968C1.83301 4.31778 4.81778 1.33301 8.49968 1.33301C12.1816 1.33301 15.1663 4.31778 15.1663 7.99968ZM9.49967 4.33301C9.49967 4.88529 9.05196 5.33301 8.49967 5.33301C7.94739 5.33301 7.49967 4.88529 7.49967 4.33301C7.49967 3.78072 7.94739 3.33301 8.49967 3.33301C9.05196 3.33301 9.49967 3.78072 9.49967 4.33301ZM6.7962 6.30006L8.96286 6.0408C9.16032 6.01886 9.33301 6.17319 9.33301 6.37186V10.333C9.33301 10.8782 9.72263 11.2005 10.1685 11.2998C10.3482 11.3398 10.4997 11.4822 10.4997 11.6663V11.9997C10.4997 12.1838 10.3504 12.333 10.1663 12.333H6.83301C6.64891 12.333 6.49967 12.1838 6.49967 11.9997V11.6663C6.49967 11.4822 6.65112 11.3398 6.83081 11.2998C7.27672 11.2005 7.66634 10.8782 7.66634 10.333V8.33301C7.66634 7.78778 7.27672 7.46551 6.83081 7.36619C6.65112 7.32617 6.49967 7.18377 6.49967 6.99967V6.63136C6.49967 6.46151 6.62739 6.31882 6.7962 6.30006Z"
        fill="currentColor"
      />
    </Icon>
  );
};
