import { Icon, IconProps } from '@node-real/icons';

export const PolyhedraIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5627 1.68293L20.2471 1.5L19.9316 1.68293L4.1759 10.8171L3.86035 11V11.3658V29.6342V30L4.1759 30.183L19.9316 39.317L20.2471 39.5L20.5627 39.317L36.3184 30.183L36.634 30V29.6342V11.3658V11L36.3184 10.8171L20.5627 1.68293ZM5.12253 27.6759V11.7317L20.2471 2.96347L35.3718 11.7317V27.6759L20.7393 9.37072L20.2471 8.75504L19.755 9.37072L5.12253 27.6759ZM5.77815 29.6483L19.7977 37.7759V25.9828C14.9703 26.0543 10.1562 27.2761 5.77815 29.6483ZM6.65668 27.7814L19.7977 11.342V24.7153C15.3018 24.7792 10.8165 25.8012 6.65668 27.7814ZM21.0598 25.9904V37.5653L34.7162 29.6483C30.4479 27.3356 25.7652 26.1163 21.0598 25.9904ZM33.8375 27.7814C29.7899 25.8546 25.4338 24.8349 21.0598 24.7226V11.7965L33.8375 27.7814Z"
        fill="url(#paint0_linear_877_32890_polyhedraicon)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_877_32890_polyhedraicon"
          x1="3.88366"
          y1="30.3653"
          x2="21.4383"
          y2="2.13865"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0CFFF0" />
          <stop offset="1" stopColor="#0CFFA7" />
        </linearGradient>
      </defs>
    </Icon>
  );
};
