import styled from '@emotion/styled';
import { Button } from '@node-real/uikit';

import { GA_MAP, reportEvent } from '../../utils/ga';
import { addDataIntoCache } from '../../utils';
import { usePausedStatus } from '../../hooks';

interface FinalizeButtonProps {
  initiateHash: string;
  setFinalizeHash: (hash: string) => void;
  setTmpCountTime: (time: number) => void;
  setIsConfirming: (isConfirming: boolean) => void;
  step: number;
  finalWithdraw: (hash: string) => Promise<string>;
  loadHistory: () => void;
  TxStatus: string[];
}

export const FinalizeButton = (props: FinalizeButtonProps) => {
  const {
    step,
    finalWithdraw,
    initiateHash,
    setFinalizeHash,
    setTmpCountTime,
    setIsConfirming,
    loadHistory,
    TxStatus,
  } = props;

  const { loadPausedStatus } = usePausedStatus();

  return (
    <WithdrawButton
      withdrawtype="finalize"
      onClick={async () => {
        reportEvent({
          name: GA_MAP.withdrawFinalizeClick,
          data: { name: 'Finalize Withdraw' },
        });
        const status = await loadPausedStatus(false);
        try {
          if (!status) {
            setIsConfirming(true);
            finalWithdraw(initiateHash)
              .then(async (res: any) => {
                if (res) {
                  setFinalizeHash(res);
                  setTmpCountTime(Date.now());
                  if (typeof window !== 'undefined') {
                    // Add temp status into cache
                    await addDataIntoCache(window.location.hostname, {
                      [`${initiateHash}`]: step,
                    });
                  }
                  loadHistory();
                }
                setIsConfirming(false);
              })
              .catch((e: any) => {
                // eslint-disable-next-line no-console
                console.log(e);
                setIsConfirming(false);
              });
          } else {
            setIsConfirming(false);
          }
        } catch (e) {
          //eslint-disable-next-line no-console
          console.log(e);
          setIsConfirming(false);
          setTmpCountTime(Date.now());
        }
      }}
    >
      {TxStatus[step]}
    </WithdrawButton>
  );
};

export const WithdrawButton = styled(Button)`
  line-height: 20px;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  color: ${(props: any) => props.theme.colors.bg?.card};
  &[disabled] {
    &,
    &:hover {
      color: ${(props: any) => props.theme.colors.readable?.disabled};
      background: ${(props: any) => props.theme.colors.bg?.bottom};
      opacity: 1;
    }
  }
`;
