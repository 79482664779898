import { ethers } from 'ethers';
import { useAccount } from 'wagmi';
import { useCallback, useState } from 'react';
import { TokenObjectType } from '@op-bridge/bridge-core';

import * as env from '../env';
import { useGetTokenList } from './useGetTokenList';

const l2RpcProvider = new ethers.providers.JsonRpcProvider(env.L2_RPC_URL, 'any');

export const useGetAutoWithdrawAllowance = () => {
  const { address } = useAccount();

  const [isLoadingL2Allowance, setIsLoadingL2Allowance] = useState(false);
  const { tokensList, isLoading } = useGetTokenList();

  // Get token allowance on L2
  const getL2Allowance = useCallback(
    async (tokenType: string) => {
      try {
        if (address && (env.NET_ENV === 'Testnet' || (env.NET_ENV === 'Mainnet' && !isLoading))) {
          const asset = tokensList.filter((asset: TokenObjectType) => asset.symbol === tokenType);

          if (Array.isArray(asset) && asset?.length) {
            setIsLoadingL2Allowance(true);
            const l2ERC20 = new ethers.Contract(asset[0].l2Address, asset[0].ABI, l2RpcProvider);
            const allowance = await l2ERC20.allowance(address, env.AUTO_WITHDRAW_ADDRESS);
            setIsLoadingL2Allowance(false);
            return allowance;
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    },
    [address, isLoading, tokensList],
  );

  return { getL2Allowance, isLoadingL2Allowance };
};
