import { Icon, IconProps } from '@node-real/icons';

export const RhinoIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_3409_106602)">
        <path
          d="M38 20C38 29.9411 29.9411 38 20 38C10.0589 38 2 29.9411 2 20C2 10.0589 10.0589 2 20 2C29.9411 2 38 10.0589 38 20Z"
          fill="white"
        />
        <path
          d="M38 20C38 29.9411 29.9411 38 20 38C10.0589 38 2 29.9411 2 20C2 10.0589 10.0589 2 20 2C29.9411 2 38 10.0589 38 20Z"
          fill="url(#paint0_radial_3409_106602)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 37.1562C29.4751 37.1562 37.1562 29.4751 37.1562 20C37.1562 10.5249 29.4751 2.84375 20 2.84375C10.5249 2.84375 2.84375 10.5249 2.84375 20C2.84375 29.4751 10.5249 37.1562 20 37.1562ZM20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z"
          fill="url(#paint1_radial_3409_106602)"
        />
        <path
          d="M29.0648 19.4343C29.688 19.4343 30.2616 19.1522 30.7861 18.6208C30.8904 18.8074 30.9414 19.0197 30.9335 19.234C30.9244 19.4731 30.8744 19.7086 30.7861 19.9305L30.8191 19.9801C31.8019 19.9304 32.3759 19.6489 32.9499 19.2011C33.0156 19.6164 33.0156 19.8008 33.0156 20.2978C33.0156 22.4218 31.1305 24.4599 28.6378 24.4599C26.1809 24.4599 24.2778 22.4367 24.2778 20.0986H24.0155C23.8517 20.5464 23.786 21.0272 23.8517 21.6738C21.1141 22.7852 20.3766 24.5593 20.3766 26.2671H18.7219C18.6345 25.3475 18.7179 24.4197 18.9676 23.5313C19.1807 22.7852 19.5743 21.823 20.3118 21.077L20.0987 20.8447C19.1159 21.6409 18.9511 21.9392 18.0988 23.0667C17.3942 23.2163 16.8033 23.6306 15.8039 23.6306C15.1647 23.6306 14.4928 23.5313 13.4108 23.0169C13.7236 22.4697 13.9354 21.9726 13.9845 21.0607H13.6565C13.0013 23.7635 10.4112 24.2108 9.29658 24.4102C8.3138 24.576 7.96893 25.3221 7.96893 26.0515C7.96741 26.1237 7.9728 26.1959 7.98501 26.2671H7.04914C7.01633 26.0253 6.99991 25.7816 7 25.5377C7 25.0072 7.03261 24.4762 7.27875 24.0288C8.16594 22.5185 8.90302 21.9545 8.90302 20.7949C8.90302 19.601 8.03415 18.8065 7.98501 18.7555C8.49339 18.671 8.67386 18.1738 9.01784 17.6434C9.77189 16.4656 11.0339 16.2836 11.6897 16.2836C12.329 16.2836 12.706 16.3663 13.3452 16.3663C16.2792 16.3663 17.7548 13 21.3281 13C23.5903 13 25.1476 14.6585 25.3767 16.796L25.4258 16.8128C25.7211 16.481 26.23 16.0332 26.8191 15.8344C26.8191 15.9175 26.8031 16.0151 26.8031 16.1163C26.8031 17.7093 27.2293 19.4343 29.0648 19.4343ZM13.6565 26.2657H12.165C11.657 25.7849 11.3291 25.0555 11.247 24.6388C12.165 24.2412 12.5751 23.9425 13.083 23.4449L13.3783 23.6772C13.1918 24.0048 13.0956 24.377 13.0995 24.755C13.0995 25.1215 13.0995 25.337 13.6565 26.2657ZM22.6232 26.2657H21.2789C21.2789 24.4418 21.8034 23.7951 22.5575 23.0653L22.787 23.246C22.4744 23.9425 22.4427 24.4233 22.4427 24.8878C22.4427 25.4866 22.5083 25.8843 22.6232 26.2657Z"
          fill="url(#paint2_radial_3409_106602)"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_3409_106602"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20 20) rotate(90) scale(18)"
        >
          <stop offset="0.603142" stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="#F99F64" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_3409_106602"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(2 2) rotate(45) scale(50.9117)"
        >
          <stop stopColor="#FED681" />
          <stop offset="1" stopColor="#F25E40" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_3409_106602"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(7 13) rotate(27.02) scale(29.2032 23.6379)"
        >
          <stop stopColor="#FED681" />
          <stop offset="1" stopColor="#F25E40" />
        </radialGradient>
        <clipPath id="clip0_3409_106602">
          <rect width="36" height="36" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </Icon>
  );
};
