import { usePausedStatus } from '../../hooks';
import { addDataIntoCache } from '../../utils';
import { GA_MAP, reportEvent } from '../../utils/ga';
import { WithdrawButton } from './FinalizeButton';

interface ProveButtonProps {
  step: number;
  proveWithdraw: (hash: string) => Promise<string>;
  initiateHash: string;
  setProveHash: (hash: string) => void;
  setTmpCountTime: (time: number) => void;
  setIsConfirming: (isConfirming: boolean) => void;
  loadHistory: () => void;
  TxStatus: string[];
}

export const ProveButton = (props: ProveButtonProps) => {
  const {
    step,
    proveWithdraw,
    initiateHash,
    setProveHash,
    setTmpCountTime,
    setIsConfirming,
    loadHistory,
    TxStatus,
  } = props;

  const { loadPausedStatus } = usePausedStatus();

  return (
    <WithdrawButton
      withdrawtype="prove"
      onClick={async () => {
        reportEvent({
          name: GA_MAP.withdrawProveClick,
          data: { name: 'Prove Withdraw' },
        });
        const status = await loadPausedStatus(false);
        setIsConfirming(true);
        try {
          if (!status) {
            proveWithdraw(initiateHash)
              .then(async (res) => {
                if (res) {
                  setProveHash(res);
                  setTmpCountTime(Date.now());
                  if (typeof window !== 'undefined') {
                    // Add temp status into cache
                    await addDataIntoCache(window.location.hostname, {
                      [`${initiateHash}`]: step,
                    });
                  }
                  loadHistory();
                }
                setIsConfirming(false);
              })
              .catch((e: any) => {
                // eslint-disable-next-line no-console
                console.log(e);
                setIsConfirming(false);
              });
          } else {
            setIsConfirming(false);
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
          setIsConfirming(false);
          setTmpCountTime(Date.now());
        }
      }}
    >
      {TxStatus[step]}
    </WithdrawButton>
  );
};
