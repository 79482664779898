import { Icon, IconProps } from '@node-real/icons';

export const USDC = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
        fill="#2775CA"
      />
      <path
        d="M8.2182 17.0016C8.2182 17.2334 8.03056 17.3658 7.80981 17.2996C4.78553 16.3393 2.6001 13.5027 2.6001 10.1583C2.6001 6.81396 4.79657 3.97731 7.80981 3.01705C8.0416 2.95082 8.2182 3.08327 8.2182 3.31506V3.90005C8.2182 4.05458 8.09679 4.24221 7.94226 4.2974C5.55815 5.15833 3.84734 7.45413 3.84734 10.1473C3.84734 12.8404 5.55815 15.1363 7.9533 16.0193C8.09679 16.0744 8.22924 16.251 8.22924 16.4166V17.0016H8.2182Z"
        fill="white"
      />
      <path
        d="M10.6484 14.691C10.6484 14.8676 10.505 15 10.3394 15H9.72129C9.54469 15 9.41224 14.8565 9.41224 14.691V13.6976C8.04359 13.5099 7.38133 12.7483 7.20473 11.7108C7.17162 11.5342 7.31511 11.3687 7.49171 11.3687H8.19811C8.3416 11.3687 8.47405 11.479 8.50716 11.6225C8.63961 12.2406 8.99281 12.7152 10.0966 12.7152C10.9023 12.7152 11.4763 12.2627 11.4763 11.5894C11.4763 10.9161 11.1341 10.6623 9.95308 10.4636C8.19811 10.2428 7.3703 9.71303 7.3703 8.33333C7.3703 7.27373 8.165 6.45695 9.41224 6.28035V5.30905C9.41224 5.13245 9.55573 5 9.72129 5H10.3394C10.516 5 10.6484 5.14349 10.6484 5.30905V6.30243C11.6529 6.47903 12.293 7.05298 12.5027 8.00221C12.5469 8.17881 12.4034 8.35541 12.2158 8.35541H11.5535C11.41 8.35541 11.2997 8.25607 11.2555 8.12362C11.0789 7.51656 10.6484 7.26269 9.89789 7.26269C9.07008 7.26269 8.63961 7.66004 8.63961 8.22296C8.63961 8.81899 8.88244 9.117 10.1518 9.2936C11.8736 9.52539 12.7566 10.0221 12.7566 11.479C12.7566 12.5938 11.9288 13.4879 10.6484 13.6976V14.691Z"
        fill="white"
      />
      <path
        d="M12.2195 17.3147C11.9877 17.3809 11.8111 17.2484 11.8111 17.0166V16.4317C11.8111 16.2551 11.9215 16.1005 12.087 16.0343C14.4822 15.1513 16.193 12.8555 16.193 10.1623C16.193 7.46918 14.4711 5.18441 12.076 4.30141C11.9325 4.24622 11.8 4.06962 11.8 3.90406V3.31907C11.8 3.08728 11.9877 2.94379 12.2084 3.02105C15.2327 3.98132 17.4181 6.81796 17.4181 10.1623C17.4292 13.5067 15.2438 16.3434 12.2195 17.3147Z"
        fill="white"
      />
    </Icon>
  );
};
