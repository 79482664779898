import { StateModal } from '@node-real/uikit';
import styled from '@emotion/styled';
import { mobileMedia } from '@op-bridge/bridge-core';

import { GA_MAP, reportEvent } from '../../utils/ga';
import { AgainButton } from './DepositSuccessful';
interface ErrorProps {
  isOpen: boolean;
  handleOpen: () => void;
  errorCode?: string;
  clearInput?: () => void;
  title: string;
}
export const TxError = (props: ErrorProps) => {
  const { isOpen, handleOpen, errorCode, clearInput, title } = props;

  return (
    <Container
      isOpen={isOpen}
      onClose={() => {
        handleOpen();
        if (clearInput) clearInput();
      }}
      margin={0}
      variant="error"
      title={title}
      description={`Error code: ${errorCode || ''}`}
      overlayProps={{
        backdropFilter: 'blur(10px)',
      }}
    >
      <AgainButton
        onClick={() => {
          reportEvent({ name: GA_MAP.tryAgain, data: { name: 'Try Again' } });
          if (clearInput) clearInput();
          handleOpen();
        }}
      >
        Try Again
      </AgainButton>
    </Container>
  );
};

const Container = styled(StateModal)`
  border-radius: 12px;
  box-shadow: ${(props: any) => props.theme.colors.shadows?.normal};
  justify-content: center;
  align-items: center;
  .ui-modal-content {
    padding: 48px 24px;
    width: 568px;
    max-height: 400px;
    overflow-y: auto;
    background: ${(props: any) => props.theme.colors.readable.normal};
    color: ${(props: any) => props.theme.colors.bg?.card};
    ${mobileMedia} {
      margin: 0 16px;
      width: 343px;
    }
  }
  .ui-modal-body {
    font-size: 18px;
    margin-top: 16px;
    color: ${(props: any) => props.theme.colors.readable.pageButton};
  }
  .ui-state-modal-title {
    font-weight: 700;
    font-size: 24px;
    margin-top: 16px;
  }
  .ui-close-button {
    color: ${(props: any) => props.theme.colors.bg?.card};
  }
`;
