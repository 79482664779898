import { ethers } from 'ethers';
import { useState, useCallback, useEffect } from 'react';
import { useAccount } from 'wagmi';
import { TokenObjectType, useCheckErc20TokenMatch } from '@op-bridge/bridge-core';

import * as env from '../env';
import { useL2Messenger } from './useL2Messenger';
import { getEtherFormatValue } from '../utils';
import { L2_RPC_URL } from '../env';
import { usePending } from './usePending';
import { useTxFailed } from './useTxFailed';
const l2RpcProvider = new ethers.providers.JsonRpcProvider(env.L2_RPC_URL, 'any');

export const useERC20Withdraw = () => {
  const { crossChainMessenger } = useL2Messenger();
  const { address: wagmiAddress } = useAccount();

  const { checkTokenAddress } = useCheckErc20TokenMatch(L2_RPC_URL);
  const { showPending: showPendingErc20, handlePendingShow: handlePendingShowErc20 } = usePending();
  const { showError: showErrorErc20, handleErrorShow: handleErrorShowErc20 } = useTxFailed();
  const [waitingConfirmationErc20, setWaitingConfirmationErc20] = useState(false);

  const [isSufficient, setIsSufficient] = useState(false);
  const [address, setAddress] = useState<null | string>(null);
  const [txFailedMsg, setTxFailedMsg] = useState('');

  useEffect(() => {
    if (wagmiAddress) {
      setAddress(wagmiAddress);
    }
  }, [wagmiAddress]);

  const withdrawERC20 = async (withdrawAmount: string, asset: TokenObjectType) => {
    if (!asset) return;
    const balance = await reportBalances(asset);
    if (!balance) {
      setIsSufficient(false);
      return;
    }

    try {
      if (crossChainMessenger && withdrawAmount) {
        handlePendingShowErc20(true);
        setWaitingConfirmationErc20(true);
        const pass = await checkTokenAddress(asset);
        if (pass) {
          // start withdraw ERC20
          const response = await crossChainMessenger.withdrawERC20(
            asset.l1Address,
            asset.l2Address,
            ethers.utils.parseUnits(withdrawAmount, 'ether'),
          );
          // eslint-disable-next-line no-console
          console.log(response);
          await response.wait();

          handlePendingShowErc20(false);
          setWaitingConfirmationErc20(false);

          return response.hash;
        } else {
          throw Error('Your selected token is under maintenance, please try again later.');
        }
      }
    } catch (e: any) {
      handlePendingShowErc20(false);
      setWaitingConfirmationErc20(false);
      handleErrorShowErc20(true);
      if (e && e?.code) {
        try {
          // do not show error if user accelerate tx (tx hash replaced)
          if (e?.code === 'TRANSACTION_REPLACED' && e?.replacement?.hash) {
            // eslint-disable-next-line no-console
            console.log(e.replacement);
            return e.replacement.hash;
          } else {
            const errorMsg = e?.code;
            setTxFailedMsg(errorMsg || e?.message);
          }
          // eslint-disable-next-line no-console
          console.log(e?.code);
        } catch (e: any) {
          setTxFailedMsg(e?.code);
        }
      } else {
        setTxFailedMsg(e || 'Transaction Failed');
      }
    }
  };

  const reportBalances = useCallback(
    async (asset: TokenObjectType) => {
      if (address) {
        try {
          const l2Signer = l2RpcProvider.getSigner(address);

          if (l2Signer && asset.l2Address && asset.ABI) {
            const l2ERC20 = new ethers.Contract(asset.l2Address, asset.ABI, l2Signer);

            const l2BalanceObj = await l2ERC20.balanceOf(address);
            const balance = await getEtherFormatValue(l2BalanceObj);
            if (balance) {
              return balance;
            } else {
              return null;
            }
          }
          return null;
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
          return null;
        }
      }
    },
    [address],
  );

  return {
    withdrawERC20,
    isSufficient,
    txFailedMsg,
    waitingConfirmationErc20,
    setWaitingConfirmationErc20,
    showPendingErc20,
    handlePendingShowErc20,
    showErrorErc20,
    handleErrorShowErc20,
  };
};
