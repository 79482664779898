import { formatTimeRange } from '@op-bridge/bridge-core';
import { Box } from '@node-real/uikit';

interface RemainingProps {
  time: number;
}

export const Remaining = (props: RemainingProps) => {
  const { time } = props;

  return (
    <Box>
      <span style={{ whiteSpace: 'nowrap' }}>{formatTimeRange(time, Date.now())} remaining</span>
    </Box>
  );
};
