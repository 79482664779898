import * as optimismSDK from '@eth-optimism/sdk';
import { useEffect, useState } from 'react';
import { useAccount, useNetwork } from 'wagmi';
import { ethers } from 'ethers';

import * as env from '../env';
import { contracts } from '../base/sdkContract';

const predeploys = require('@eth-optimism/contracts');

const l1ChainId = Number(env.L1_CHAIN_ID);
const l2ChainId = Number(env.L2_CHAIN_ID);
const selfL1StandardBridge = env.L1StandardBridge;

const l1RpcProvider = new ethers.providers.JsonRpcProvider(env.L1_RPC_URL, 'any');

export const useL2Messenger = () => {
  const { connector, address } = useAccount();
  const { chain } = useNetwork();
  const [crossChainMessenger, setCrossChainMessenger] =
    useState<optimismSDK.CrossChainMessenger | null>(null);
  function useMySigner({ chainId }: { chainId: number }) {
    const [signer, setSigner] = useState(null);
    const { connector } = useAccount();

    useEffect(() => {
      const fetchSigner = async () => {
        const signer = (await connector?.getSigner?.({ chainId })) || null;
        setSigner(signer);
      };

      fetchSigner();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chainId, connector, address]);

    return { data: signer };
  }

  const { data: signer2 } = useMySigner({
    chainId: l2ChainId,
  });

  useEffect(() => {
    if (address && signer2) {
      const l1Signer = l1RpcProvider.getSigner(address);
      try {
        const messenger = new optimismSDK.CrossChainMessenger({
          l1ChainId: l1ChainId,
          l2ChainId: l2ChainId,
          l1SignerOrProvider: l1Signer,
          l2SignerOrProvider: signer2,
          bedrock: true,
          contracts: contracts,
        });
        const bridgess = optimismSDK.getBridgeAdapters(l2ChainId, messenger, {
          overrides: {
            Standard: {
              Adapter: optimismSDK.StandardBridgeAdapter,
              l1Bridge: selfL1StandardBridge,
              l2Bridge: predeploys.predeploys.L2StandardBridge,
            },
            ETH: {
              Adapter: optimismSDK.ETHBridgeAdapter,
              l1Bridge: selfL1StandardBridge,
              l2Bridge: predeploys.predeploys.L2StandardBridge,
            },
          },
        });

        messenger.bridges = bridgess;
        setCrossChainMessenger(messenger);
      } catch (e) {
        //eslint-disable-next-line no-console
        console.log(e);
      }
    }
  }, [connector, signer2, address, chain]);

  return { crossChainMessenger };
};
