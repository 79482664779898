import { useState } from 'react';
import { TokenObjectType } from '@op-bridge/bridge-core';

import { DepositButton } from './Deposit';
import { reportEvent, GA_MAP } from '../utils/ga';
import { useERC20Deposit, useGetAllowance } from '../hooks';
import { WaitingForConfirmation } from './modal/WaitingForConfirmation';
import { TxError } from './modal/TxError';
import { batchUpdate } from '../utils';
interface ERC20DepositButtonProps {
  disabled: boolean;
  asset: TokenObjectType[];
  estimateGas: number;
  depositVal: string;
  updateBalance: () => void;
  handleAllowanceUpdate: (amount: number) => void;
  setTxHash: (tx: string) => void;
  handleSuccessfulShow: (show: boolean) => void;
}

export const ERC20DepositButton = (props: ERC20DepositButtonProps) => {
  const {
    disabled,
    asset,
    estimateGas,
    depositVal,
    updateBalance,
    handleAllowanceUpdate,
    setTxHash,
    handleSuccessfulShow,
  } = props;
  const { getL1Allowance } = useGetAllowance();

  const {
    depositERC20,
    txFailedMsg: depositErrorMsg,
    handlePendingShow,
    waitingConfirmation,
    showPending,
    showError,
    handleErrorShow,
  } = useERC20Deposit();
  const [txFailedMsg, setTxFailedMsg] = useState('');

  return (
    <>
      <DepositButton
        disabled={disabled}
        onClick={async () => {
          reportEvent({ name: GA_MAP.depositTokenClick, data: { name: 'Click ERC20 Deposit' } });
          try {
            if (asset && asset?.length > 0) {
              let gasLimit = Math.ceil(Number(estimateGas) * Math.pow(10, 18) * 1.5);
              if (!gasLimit || gasLimit < 300000) {
                gasLimit = 300000;
              }
              depositERC20(depositVal, asset[0], gasLimit)
                .then((res) => {
                  getL1Allowance(asset[0].symbol).then((res) => {
                    handleAllowanceUpdate(Number(res) * Math.pow(10, -18));
                  });
                  if (res) {
                    setTxHash(res.hash);
                    handleSuccessfulShow(true);
                  }
                })
                .catch((e: any) => {
                  batchUpdate(() => {
                    if (e && e?.code) {
                      setTxFailedMsg(e?.code);
                    } else {
                      setTxFailedMsg(e);
                    }
                    handleErrorShow(true);
                  });
                  // eslint-disable-next-line no-console
                  console.log(e);
                });
            } else {
              // eslint-disable-next-line no-console
              console.log('no asset found');
            }
          } catch (e: any) {
            // eslint-disable-next-line no-console
            console.log(e);
            batchUpdate(() => {
              if (e && e?.code) {
                setTxFailedMsg(e?.code);
              } else {
                setTxFailedMsg(e);
              }
              handleErrorShow(true);
            });
          }
          updateBalance();
        }}
      >
        Deposit
      </DepositButton>
      {waitingConfirmation ? (
        <WaitingForConfirmation
          isOpen={showPending}
          handleOpen={handlePendingShow}
        ></WaitingForConfirmation>
      ) : null}

      {(txFailedMsg || depositErrorMsg) && (
        <TxError
          isOpen={showError}
          title="Transaction Failed"
          handleOpen={() => {
            handleErrorShow(false);
          }}
          errorCode={txFailedMsg || depositErrorMsg}
        />
      )}
    </>
  );
};
