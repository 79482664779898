import { useCallback, useState } from 'react';
import { BigNumber, ethers } from 'ethers';
import { useAccount, useNetwork } from 'wagmi';

import { getEtherFormatValue } from '../utils';
import { useL1Withdraw } from './useL1Withdraw';
import * as env from '../env';

export const useGetGasPrice = () => {
  const { crossChainMessenger: c1 } = useL1Withdraw();
  const { chain: currentChain } = useNetwork();
  const { address } = useAccount();
  const [loadingGasLimit, setLoadingGasLimit] = useState(false);
  const [isLoadingGasPrice, setIsLoadingGasPrice] = useState(false);

  const getEstimateGas = useCallback(
    async (tokeNumber: string, type: string) => {
      if (c1) {
        setLoadingGasLimit(true);
        let estiGas;
        try {
          if (type === 'deposit' && currentChain && currentChain.id === Number(env.L1_CHAIN_ID)) {
            estiGas = await c1.estimateGas.depositETH(
              ethers.utils.parseUnits(tokeNumber, 'ether'),
              {
                l2GasLimit: 1,
              },
            );
          }
          return estiGas
            ? { val: await getEtherFormatValue(estiGas), error: false, msg: '' }
            : {
                val: await getEtherFormatValue(BigNumber.from('0')),
                error: true,
                msg: 'Unable to get estimated gas from op SDK',
              };
        } catch (e: any) {
          //eslint-disable-next-line no-console
          console.log(e);
          const bn0 = BigNumber.from('0');
          return { val: await getEtherFormatValue(bn0), error: true, msg: e?.code };
        } finally {
          setLoadingGasLimit(false);
        }
      }
    },
    [address, c1, currentChain],
  );

  const getEstimateApproveGas = async (
    l1Address: string,
    l2Address: string,
    tokeNumber: string,
  ) => {
    if (c1 && l1Address && l2Address && address) {
      // approve ERC20 deposit estimated gas
      setLoadingGasLimit(true);
      try {
        const appEstiGas = await c1.estimateGas.approveERC20(
          l1Address,
          l2Address,
          ethers.utils.parseUnits(tokeNumber, 'ether'),
          { overrides: { from: address } },
        );
        // console.log('approve estimate gas sdk output ', appEstiGas);
        return { val: await getEtherFormatValue(appEstiGas), error: false, msg: '' };
      } catch (e: any) {
        // eslint-disable-next-line no-console
        console.log(e);
        const bn = BigNumber.from('0');
        return { val: await getEtherFormatValue(bn), error: true, msg: e?.code };
      } finally {
        setLoadingGasLimit(false);
      }
    }
  };

  const getEstimateERC20Gas = async (
    l1Address: string,
    l2Address: string,
    tokeNumber: string,
    type: string,
  ) => {
    if (c1 && l1Address && l2Address && address) {
      setLoadingGasLimit(true);
      let estiGas;
      try {
        if (type === 'deposit') {
          estiGas = await c1.estimateGas.depositERC20(
            l1Address,
            l2Address,
            ethers.utils.parseUnits(tokeNumber, 'ether'),
            { overrides: { from: address }, l2GasLimit: 1 },
          );
        } else {
          estiGas = await c1.estimateGas.withdrawERC20(
            l1Address,
            l2Address,
            ethers.utils.parseUnits(tokeNumber, 'ether'),
            { overrides: { from: address } },
          );
        }
        return { val: await getEtherFormatValue(estiGas), error: false, msg: '' };
      } catch (e: any) {
        //eslint-disable-next-line no-console
        console.log(e);
        const bn = BigNumber.from('0');
        return { val: await getEtherFormatValue(bn), error: true, msg: e.message };
      } finally {
        setLoadingGasLimit(false);
      }
    }
  };

  const getGasPrice = async (chainRpc: string) => {
    const l1provider = new ethers.providers.JsonRpcProvider(chainRpc);
    setIsLoadingGasPrice(true);
    try {
      const gasPriceBignumber = await l1provider.getGasPrice();
      const gasPrice = await getEtherFormatValue(gasPriceBignumber);
      setIsLoadingGasPrice(false);
      return gasPrice;
    } catch (e: any) {
      return { val: 0, error: true, msg: e };
    }
  };

  return {
    getEstimateGas, // for deposit
    getEstimateERC20Gas,
    getEstimateApproveGas,
    isLoadingPrice: loadingGasLimit, // Estimated gas limit loading
    getGasPrice,
    isLoadingGasPrice, // L1 or L2 gas price loading
  };
};
