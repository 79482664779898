import React, { forwardRef, ForwardedRef, ReactNode, useState } from 'react';
import {
  Button,
  Flex,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Box,
  Image,
  useOutsideClick,
} from '@node-real/uikit';
import { MenuCloseIcon } from '@node-real/icons';
import { useAccount } from 'wagmi';
import { TokenObjectType } from '@op-bridge/bridge-core';

import { TokenBalance } from './TokenBalance';
import { menus } from './Menu';
import { L1_CHAIN_ID, L1_RPC_URL, L2_CHAIN_ID, L2_RPC_URL } from '../env';

interface TokenSelectionProp {
  handleTokenSelection: (item: string) => void;
  value: string;
  type?: string;
  onMenuButtonClick: () => void;
  loadingComponent: ReactNode;
  assetList: TokenObjectType[];
}

const l1ChainId = L1_CHAIN_ID;
const l2ChainId = L2_CHAIN_ID;
const l1Rpc = L1_RPC_URL;
const l2Rpc = L2_RPC_URL;

export const TokenSelection = (props: TokenSelectionProp) => {
  const { isConnected } = useAccount();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isMenuOpenRef = React.useRef();

  useOutsideClick({
    ref: isMenuOpenRef as any,
    handler: () => {
      if (isMenuOpen === true) {
        setIsMenuOpen(false);
      }
    },
  });

  const {
    handleTokenSelection,
    value,
    type = 'deposit',
    onMenuButtonClick,
    loadingComponent,
    assetList,
  } = props;

  const token = assetList.filter((item) => item.symbol === value)[0];
  return (
    <Menu placement="bottom-end">
      <MenuButton
        ref={isMenuOpenRef as any}
        onClick={() => {
          onMenuButtonClick();
          setIsMenuOpen((pre) => !pre);
        }}
        as={CustomMenuButton}
      >
        {token && token.logoURI ? (
          <Box width={20} mr={8}>
            <Image
              flex={1}
              loading={'lazy'}
              width={20}
              height={20}
              alt={token.name}
              src={token.logoURI}
              borderRadius={'50%'}
            />
          </Box>
        ) : (
          <Box mr={8}>{menus.filter((item) => item.name === value)[0]?.icon}</Box>
        )}
        <div>{value}</div>
      </MenuButton>
      <MenuList maxH={360} overflowY={'auto'} borderRadius={8} background={'readable.normal'}>
        {assetList.map((item, index) => (
          <MenuItem
            className={value === item.symbol ? 'selected' : ''}
            minW={115}
            p={12}
            justifyContent={'space-between'}
            alignContent={'center'}
            key={index}
            background={'readable.normal'}
            _hover={{ background: 'bg.top.normal' }}
            sx={{
              '&.selected': {
                backgroundColor: 'scene.primary.semiOpacity',
              },
            }}
            onClick={() => {
              handleTokenSelection(item.symbol);
            }}
          >
            <Flex alignItems={'center'}>
              {item.logoURI ? (
                <Box width={20} mr={4}>
                  <Image
                    flex={1}
                    loading={'lazy'}
                    width={20}
                    height={20}
                    alt={item.name}
                    src={item.logoURI}
                    borderRadius={'50%'}
                  />
                </Box>
              ) : (
                <Box mr={4}>{menus.filter((asset) => asset.name === item.symbol)[0]?.icon}</Box>
              )}

              <Box whiteSpace={'nowrap'} color={'bg.card'}>
                {item.symbol}
              </Box>
            </Flex>
            {isConnected ? (
              <TokenBalance
                isMenuOpen={isMenuOpen}
                type={type}
                tokenName={item.symbol}
                l1ChainId={l1ChainId}
                l2ChainId={l2ChainId}
                l1Rpc={l1Rpc}
                l2Rpc={l2Rpc}
                loadingComponent={loadingComponent}
                assetList={assetList}
              />
            ) : null}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

const CustomMenuButton = forwardRef(
  (props: { children: ReactNode }, ref: ForwardedRef<HTMLButtonElement>) => {
    const { children, ...restProps } = props;

    return (
      <Button
        ref={ref}
        w={116}
        borderLeft={'1px solid readable.disabled'}
        variant="ghost"
        justifyContent="space-between"
        background={'bg.status'}
        pl={[8, 12, 12]}
        pr={[4, 12, 12]}
        fontWeight={[700, 600, 600]}
        fontSize={[16, 14, 14]}
        lineHeight={'17px'}
        border={'none'}
        mr={1}
        borderTopRightRadius={8}
        borderBottomRightRadius={8}
        borderBottomLeftRadius={0}
        borderTopLeftRadius={0}
        _hover={{
          background: 'readable.disabled',
        }}
        _expanded={{
          '.close-icon': {
            transform: 'rotate(-180deg)',
          },
        }}
        {...restProps}
      >
        <Flex align={'center'}>{children}</Flex>
        <MenuCloseIcon className="close-icon" transitionDuration="normal" />
      </Button>
    );
  },
);
