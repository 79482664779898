import { formatLocalTime } from '../../utils';

interface EstimatedArrivalProps {
  time: number;
}

export const EstimatedArrival = (props: EstimatedArrivalProps) => {
  const { time } = props;

  return <div>Estimated arrival at {formatLocalTime(time, 'YYYY-M-DD HH:mm')}</div>;
};
